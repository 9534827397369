import { useRecoilState } from "recoil";
import { ConstantState } from "../store/states";
import { CalendarMeta, ConstantKeys, ConstantsAPIResult, CustomImage, makeItemMetaTreeNodes } from "../types/elements";
import { Ls } from "../api";
import { INIT_ITEM_META_STATE, ItemMetaState, ItemMetaStateIF } from "../store/states/ItemMetaState";
import { ConstantsAPI } from "../api/ConstantsAPI";

const useConstant = () => {
  const [constantState, setConstantState] = useRecoilState(ConstantState);
  const [itemMetaState, setItemMetaState] = useRecoilState(ItemMetaState);

  const load = async () => {
    const apiResult = await ConstantsAPI.getConstants();
    const result = apiResult.data as ConstantsAPIResult;

    const cs = { ...constantState };
    if (result.constants) cs.constants = result.constants;
    if (result.banners) cs.banners = result.banners;
    setConstantState(cs);
    Ls.setItems("CONSTANTS_BANNERS", cs.banners ?? []);
    // save local storage
    if (result.constants) Ls.setItems("CONSTANTS", result.constants);

    const itemMetas: ItemMetaStateIF = { ...INIT_ITEM_META_STATE };
    if (result.brand) {
      itemMetas.brand = result.brand
        .map(o => ({ ...o, name: (o.name ?? "").trim() }))
        .sort((a, b) => ((a.name ?? "") > (b.name ?? "") ? 1 : -1));
    }
    if (result.designer) {
      itemMetas.designer = result.designer
        .map(o => ({ ...o, name: (o.name ?? "").trim() }))
        .sort((a, b) => ((a.name ?? "") > (b.name ?? "") ? 1 : -1));
    }
    if (result.category) {
      const categories = result.category.map(o => ({ ...o }));
      itemMetas.topCategory = makeItemMetaTreeNodes(categories);
      itemMetas.category = categories.sort((a, b) => (a.seq && b.seq && a.seq > b.seq ? 1 : -1));
    }

    itemMetas.isLoaded = true;
    setItemMetaState(itemMetas);
  };

  const getConstant = (key: ConstantKeys, defaultValue?: string) => {
    const result = constantState.constants.filter(o => o.key === key);
    if (result.length === 1) {
      return result[0].value;
    } else {
      return defaultValue ? defaultValue : "";
    }
  };

  const getCustomImages = (key: ConstantKeys): CustomImage[] => {
    const ciConstant = constantState.constants.find(o => o.key === key);
    if (typeof ciConstant?.value === "string") {
      try {
        const cis: CustomImage[] = JSON.parse(ciConstant.value);
        return cis;
      } catch (err) {
        console.log("Error ", err);
      }
    }
    return [];
  };
  const getCalendarMetas = (): CalendarMeta[] => {
    const cm = constantState.constants.find(o => o.key === "CalendarMeta");
    if (typeof cm?.value === "string") {
      try {
        const cms: CalendarMeta[] = JSON.parse(cm.value);
        return cms;
      } catch (err) {}
    }
    return [];
  };

  return {
    isLoaded: itemMetaState.isLoaded,
    load,
    getConstant,
    getCustomImages,
    getCalendarMetas,
  };
};
export default useConstant;
