import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

interface BottomNavProps {
  onClickMenu: () => void;
}
const BottomNav: React.FC<BottomNavProps> = props => {
  const navi = useNavigate();
  const go = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    navi(path);
  };
  const { auth } = useAuth();

  const [menu, setMenu] = useState<"intro" | "home" | "noti" | "mypage" | "">("");
  useEffect(() => {
    if (window.location.pathname === "/intro") setMenu("intro");
    else if (window.location.pathname === "/") setMenu("home");
    else if (window.location.pathname === "/noti") setMenu("noti");
    else if (window.location.pathname === "/mypage") setMenu("mypage");
    else setMenu("");
  }, [window.location.pathname]);

  return (
    <div className="a-header--bundle">
      <button type="button" className="btn_menu" onClick={() => props.onClickMenu()}>
        메뉴
      </button>
      <a href="/intro" className={"btn_intro" + (menu === "intro" ? " on " : "")} onClick={go("/intro")}>
        소개
      </a>
      <a
        href="/"
        className={"btn_home" + (menu === "home" ? " on " : "")}
        // onClick={go("/")}
      >
        홈
      </a>
      <a
        href="/noti"
        className={classNames("btn_alarm", { on: menu === "noti", new: (auth.num_unread_noti ?? 0) > 0 })}
        onClick={go("/noti")}>
        {/* new class */}
        알림
      </a>
      <a href="/mypage" className={"btn_mypage" + (menu === "mypage" ? " on " : "")} onClick={go("/mypage")}>
        마이
      </a>
    </div>
  );
};

export default BottomNav;
