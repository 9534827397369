// import Axios from "axios";
import { WrapperAxios as Axios } from "./base";
import {
  CommonUtil,
  ItemSearchOption,
  APIResponse,
  API_PATH,
  Item,
  // SESSION_KEY,
  SearchResult,
} from "../types/elements";
import { convertItem } from "../util/converter";

export const ItemAPI = {
  getItems: (opt: ItemSearchOption): Promise<APIResponse<SearchResult<Item>>> => {
    const query = CommonUtil.createQueryString(opt);
    console.log(query);
    return Axios.get("/api" + API_PATH.ITEM + query);
  },

  // deprecated
  getItem: async (itemId: number, opt?: { withStock?: boolean }): Promise<APIResponse<Item>> => {
    // TODO refactoriing required.
    try {
      let query = "";
      if (opt && opt.withStock !== undefined) {
        query += "?withStock=" + opt.withStock;
      }
      const result = await Axios.get("/api" + API_PATH.ITEM + "/" + itemId + query);
      result.data = convertItem(result.data);
      return result;
    } catch (err) {
      throw err;
    }
  },

  // postFavorite: (itemId: number): Promise<APIResponse<any>> => {
  //   return Axios.post(`/api${API_PATH.ITEM}/${itemId}/favorite`);
  // },
  // deleteFavorite: (itemId: number): Promise<APIResponse<any>> => {
  //   return Axios.delete(`/api${API_PATH.ITEM}/${itemId}/favorite`);
  // },
};
