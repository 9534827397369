import { Popper } from "@mui/material";
import React from "react";
import { useRecoilValue } from "recoil";
import { PopperState } from "../../store/states/PopperState";

/**
 * TODO develop it
 * @param props
 */
const TopPopper: React.FC = props => {
  const popperState = useRecoilValue(PopperState);

  return (
    <div>
      <Popper className={"topPopper "} open={popperState.open === true} anchorEl={popperState.el}>
        {popperState.content}
      </Popper>
    </div>
  );
};

export default TopPopper;
