import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ItemAPI, Ls } from "../../../../api";
import { Item } from "../../../../types/elements";

const MainNewItemSlide = ({ item }: { item: Item }) => {
  const navi = useNavigate();
  const mouseLast = useRef(0);
  const mouseX = useRef(0);
  const mouseY = useRef(0);
  return (
    <div className="main__new--item">
      <a
        href="#"
        tabIndex={0}
        // onClick={e => navi("/item/" + item.id)}    // 드래그시에도 클릭되는 문제로 수정
        onMouseDown={e => {
          mouseLast.current = Date.now();
          mouseX.current = e.screenX;
          mouseY.current = e.screenY;
        }}
        onMouseUp={e => {
          const mouseGap = Date.now() - mouseLast.current;
          const mousePosGap = Math.floor(
            Math.sqrt(Math.abs(e.screenX - mouseX.current) ** 2 + Math.abs(e.screenY - mouseY.current) ** 2)
          );

          // clicked // else -> moved
          if (mouseGap < 100 && mousePosGap < 100) navi("/item/" + item.id);

          mouseLast.current = Date.now();
        }}>
        <img src={item.topImages && item.topImages.length > 0 && item.topImages[0].url ? item.topImages[0].url : ""} />
      </a>
    </div>
  );
};

interface MainNewItemListProps {}
const MainNewItemList: React.FC<MainNewItemListProps> = props => {
  const [newItems, setNewItems] = useState<Item[]>((Ls.getItems("MAINVIEW_NEW_ITEMS") ?? []) as Item[]);

  useEffect(() => {
    ItemAPI.getItems({ limit: 9, ordering: "recently" }).then(response => {
      const result = response.data.result;
      if (result && result.list) {
        const ns = newItems
          .map(o => o.id ?? 0)
          .sort((a, b) => (a > b ? 1 : -1))
          .join(",");
        const rs = result.list
          .map(o => o.id ?? 0)
          .sort((a, b) => (a > b ? 1 : -1))
          .join(",");
        if (ns !== rs) setNewItems(result.list);
        Ls.setItems("MAINVIEW_NEW_ITEMS", result.list);
      }
    });
  }, []);

  return (
    <>
      <Slider
        className="slider main__new"
        adaptiveHeight={false}
        slidesToShow={3}
        arrows={true}
        slidesToScroll={3}
        draggable>
        {newItems.map(item => (
          <MainNewItemSlide item={item} key={"main_new_item" + item.id} />
        ))}
      </Slider>
    </>
  );
};

export default MainNewItemList;
