import React from "react";
import { useNavigate } from "react-router-dom";
import { Banner } from "../../../types/elements";
import useBanners from "../../../hooks/useBanners";

interface BannerBarProps {
  banners: Banner[];
}
const BannerBar: React.FC<BannerBarProps> = ({ banners }) => {
  const navi = useNavigate();
  const { closeBanner } = useBanners();

  const handleClick = (e: any) => {
    const bannerUrl = banners.length > 0 ? banners[0].url ?? "" : "";
    if (bannerUrl.indexOf("/") === 0) {
      navi(bannerUrl);
    }
  };

  const handleClose = () => {
    const id = banners[0].id;
    if (id) closeBanner(id);
  };

  return (
    <div className="a-header--banner">
      <p>
        {banners.length > 0 && (
          <a href="#" onClick={handleClick}>
            {banners[0].msg}
          </a>
        )}
      </p>
      <button type="button" className="a-header--banner__close" onClick={handleClose}>
        <span className="blind">배너닫기</span>
      </button>
    </div>
  );
};

export default BannerBar;
