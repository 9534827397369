import { useRecoilState, useSetRecoilState } from "recoil";
import { setSessionKey } from "../api";
import { RnAPI } from "../api/RnAPI";
import { gqlapi } from "../graphql/gqlapi";
import { AuthState, SnackStateSelector } from "../store/states";

const useAuth = () => {
  const [authState, setAuthState] = useRecoilState(AuthState);
  const openSnack = useSetRecoilState(SnackStateSelector);
  const checkAuth = (opt?: { showSnack?: boolean; moveLoginAfterSnack?: boolean }) => {
    if (authState.loaded === false) {
      if (opt?.showSnack) {
        openSnack({ msg: "로그인이 필요합니다." });
      }
    }
    return authState.loaded;
  };

  const getAuth = async (isForce?: boolean) => {
    if (authState.loaded === false || isForce) {
      return gqlapi.getInitialData().then(user => {
        if (user) {
          setAuthState(as => ({ ...as, data: user, loaded: true, status: "DONE" }));
          return true;
        }
        return false;
      });
    }
    return authState.loaded;
  };

  const refreshFavorites = () => {
    gqlapi.getFavorites().then(result => {
      setAuthState({ ...authState, data: { ...authState.data, favorites: result } });
    });
  };
  const logout = async () => {
    setAuthState({ ...authState, status: "REQUESTED" });
    return gqlapi.logout().then(result => {
      if (result.ok) {
        setSessionKey("");
        setAuthState({ ...authState, loaded: false, data: {}, status: "DONE" });
        RnAPI.postMessage({ type: "logout" });
      }
      return result;
    });
  };

  const refreshUnreadNotiCount = () => {
    gqlapi.getUnreadUserNotiCount().then(result => {
      setAuthState(as => ({ ...as, data: { ...as.data, num_unread_noti: result } }));
    });
  };
  return {
    authState,
    setAuthState,
    status: authState.status,
    auth: authState.data,
    checkAuth,
    getAuth,
    refreshFavorites,
    logout,
    refreshUnreadNotiCount,
  };
};

export default useAuth;
