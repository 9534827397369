import Axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { FCM_TOKEN, SESSION_KEY } from "../types/elements";
import { getFcmToken, getSessionKey } from "./Ls";
const getHeaders = (headers?: any) => {
  const h = headers ?? {};
  h[SESSION_KEY] = getSessionKey();
  h[FCM_TOKEN] = getFcmToken();
  return h;
};

export const WrapperAxios = {
  ...Axios,
  get: (url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
    const _config: any = { ...config };
    _config["headers"] = getHeaders(_config.headers);
    return Axios.get(url, _config);
  },
  post: (url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
    const _config: any = { ...config };
    _config["headers"] = getHeaders(_config.headers);
    return Axios.post(url, data, _config);
  },
  put: (url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
    const _config: any = { ...config };
    _config["headers"] = getHeaders(_config.headers);
    return Axios.put(url, data, _config);
  },
  delete: (url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
    const _config: any = { ...config };
    _config["headers"] = getHeaders(_config.headers);
    return Axios.delete(url, _config);
  },
};
