import React, { KeyboardEvent } from "react";
import { useRecoilState } from "recoil";
import { SnackState } from "../../store/states/SnackState";
import { useNavigate } from "react-router-dom";

import SimpleDialog from "../atoms/SimpleDialog";
import ButtonBox from "../molecules/ButtonBox";

interface TopSnackProps {
  isMobile?: boolean;
}
const TopSnack: React.FC<TopSnackProps> = props => {
  const navi = useNavigate();

  const [snackState, setSnackState] = useRecoilState(SnackState);

  const handleMove = (path?: string, onClick?: () => any) => {
    if (onClick) onClick();
    if (path) navi(path);
    setSnackState({ ...snackState, open: false });
  };

  const handleClose = () => {
    setSnackState({ ...snackState, open: false });
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && snackState.open === true) handleClose();
  };
  return (
    <div className={"topSnack "} onKeyPress={handleKeyDown}>
      <SimpleDialog
        className={"topSnackSimpleDialogRoot " + (props.isMobile ? " m " : " pc ")}
        open={snackState.open === true}
        onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: props.isMobile ? "2.66vw" : "0.5rem",
            right: props.isMobile ? "2.66vw" : "0.5rem",
            cursor: "pointer",
          }}
          onClick={handleClose}>
          <img
            alt="close"
            src="/static/icon_close.png"
            style={{ width: props.isMobile ? "2.66vw" : undefined, height: props.isMobile ? "2.66vw" : undefined }}
          />
        </div>

        <div className="content">
          <div style={{ fontSize: props.isMobile ? "3.99vw" : "1rem" }}>{snackState.msg}</div>
          {snackState.boldMsg &&
            snackState.boldMsg.split("\n").map((boldStr, boldStrIdx) => (
              <div
                key={boldStrIdx}
                style={{
                  fontSize: props.isMobile ? "3.99vw" : "1rem",
                  fontWeight: "bold",
                }}>
                {boldStr}
              </div>
            ))}
          <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "1rem" }}>
            <ButtonBox
              boxStyle="black"
              style={{
                border: "2px solid black",
                fontSize: props.isMobile ? "3.55vw" : "0.7rem",
                fontWeight: "bold",
                width: props.isMobile ? "21.28vw" : undefined,
                height: props.isMobile ? "8.87vw" : undefined,
              }}
              onClick={handleClose}>
              확인
            </ButtonBox>
            {snackState.move && (
              <ButtonBox
                style={{
                  marginLeft: "1rem",
                  border: "2px solid black",
                  fontSize: props.isMobile ? "3.55vw" : "0.7rem",
                  fontWeight: "bold",
                  width: props.isMobile ? "21.28vw" : undefined,
                  height: props.isMobile ? "8.87vw" : undefined,
                }}
                onClick={() => handleMove(snackState.move?.path, snackState.move?.onClick)}>
                {snackState.move.name}
              </ButtonBox>
            )}
          </div>
        </div>
      </SimpleDialog>
    </div>
  );
};

export default TopSnack;
