import React from "react";

import { Dialog, DialogContent } from "@mui/material";

interface SimpleDialogProps {
  className?: string;
  open: boolean;
  onClose: () => any;
  children?: React.ReactNode;
}
const SimpleDialog: React.FC<SimpleDialogProps> = props => {
  return (
    <Dialog className={props.className} open={props.open} onClose={props.onClose}>
      {props.children && <DialogContent>{props.children}</DialogContent>}
    </Dialog>
  );
};

export default SimpleDialog;
