import { Item, Image } from "../types/elements";

export const convertItem = (item: Item): Item => {
  const _item: Item = { ...item };
  // if (_item.created && typeof _item.created === 'number') {
  //     const created: any = _item.created;
  //     _item.created = new Date(created);
  // }
  if (!_item.images) {
    _item.images = [];
  }
  if (!_item.topImages) {
    _item.topImages = [];
  }
  // if (!_item.descImages) {
  //     _item.descImages = [];
  // }
  return _item;
};

export const convertImage = (image: Image): Image => {
  const _image: Image = { ...image };
  return _image;
};
