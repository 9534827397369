import {
  ApolloClient,
  ApolloQueryResult,
  createHttpLink,
  DefaultContext,
  FetchResult,
  InMemoryCache,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { LogAPI } from "../api/LogAPI";
import { getFcmToken, getSessionKey } from "../api/Ls";
import { Log, SESSION_KEY, FCM_TOKEN } from "../types/elements";

const httpLink = createHttpLink({ uri: "/api/gql" });

const authLink = setContext((_: any, { headers }) => {
  const _headers = { ...headers };
  _headers[SESSION_KEY] = getSessionKey();
  _headers[FCM_TOKEN] = getFcmToken();
  _headers["x-target"] = "andersonc-web";

  return {
    headers: _headers,
  };
});

// export const client: ApolloClient<any> = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache(),
// });

const createApolloClient = () => {
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const _query = client.query;
  // wrapping Error Catching
  client.query = <T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>
  ): Promise<ApolloQueryResult<T>> => {
    const log: Log = {
      started: Date.now(),
      url: window.location.href,
      target: "GRAPHQL_WEBAPP",
      msg: "Query",
      data: {
        query: options.query.loc?.source?.body,
        variables: options.variables,
      },
    };
    options.fetchPolicy = options.fetchPolicy ? options.fetchPolicy : "no-cache";
    return _query(options)
      .then(result => {
        log.ended = Date.now();
        log.elapsed = log.ended - (log.started ? log.started : 0);
        log.status = "SUCCESS";
        if (log.elapsed > 1000) LogAPI.post(log).catch(err => {});
        return result;
      })
      .catch(err => {
        console.log("GraphQL Error Catching", err);
        log.ended = Date.now();
        log.elapsed = log.ended - (log.started ? log.started : 0);
        log.status = "ERROR";
        log.data = { ...log.data, error: err };
        // LogAPI.post(log).catch(err => {});
        throw err;
      });
  };
  const _mutate = client.mutate;

  client.mutate = <
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext
    // TCache extends ApolloCache<any> = ApolloCache<any>
  >(
    options: MutationOptions<TData, TVariables, TContext>
  ): Promise<FetchResult<TData>> => {
    const log: Log = {
      started: Date.now(),
      url: window.location.href,
      target: "GRAPHQL_WEBAPP",
      msg: "Mutation",
      data: {
        query: options.mutation.loc?.source?.body,
        variables: options.variables,
      },
    };
    return _mutate(options)
      .then(result => {
        log.ended = Date.now();
        log.elapsed = log.ended - (log.started ? log.started : 0);
        log.status = "SUCCESS";
        LogAPI.post(log).catch(err => {});
        return result;
      })
      .catch(err => {
        console.log("GraphQL Mutation Error Catching", err);
        log.ended = Date.now();
        log.elapsed = log.ended - (log.started ? log.started : 0);
        log.status = "ERROR";
        log.data = { ...log.data, error: err };
        LogAPI.post(log).catch(err => {});
        throw err;
      });
  };

  return client;
};

export const client: ApolloClient<any> = createApolloClient();
