import { atom } from 'recoil';


interface PopperStateIF {
    open?: boolean;
    el?: any;
    content?: any;
}

const INIT_POPPER_STATE: PopperStateIF = {
    open: false,
    content: ''
}

export const PopperState = atom<PopperStateIF>({
    key: 'PopperState',
    default: INIT_POPPER_STATE
});

