import {
  Item,
  User,
  ItemSearchOption,
  SearchResult,
  ItemOption,
  convertItem,
  convertItemOption,
  convertConstant,
  convertUser,
  SimpleResult,
  Cart,
  convertBanner,
  OrderSearchOption,
  Order,
  convertOrder,
  convertMyOrder,
  MyOrder,
  ReserveSlotSearchOption,
  ReserveSlot,
  convertReserveSlot,
  Reservation,
  convertItemMeta,
  Consignment,
  ConsignmentSearchOption,
  convertConsignment,
  ServiceRequestSearchOption,
  ServiceRequest,
  ConsignmentItem,
  convertConsignmentItem,
  RentalSearchOption,
  Rental,
  Styling,
  convertRental,
  StylingSearchOption,
  convertStyling,
  Board,
  BoardSearchOption,
  convertBoard,
  convertBoardBlock,
  convertSimpleResult,
  ConstantsAPIResult,
  convertReservation,
  ReservationSearchOption,
  ItemPrice,
  convertItemPrice,
} from "../types/elements";
import { client } from "./client";
import { gql } from "@apollo/react-hooks";
import {
  alertInfos,
  delAllUserNoti,
  delUserNoti,
  getUnreadUserNotiCount,
  getUserNotis,
  markReadUserNoti,
  putUserProfile,
  setUserAlertInfo,
  toggleFavorite,
} from "./gql_user";

interface QueryIF extends ConstantsAPIResult {
  // extends below ConstantsAPIResult
  // constants?: Constant[];
  // brand?: ItemMeta[];
  // designer?: ItemMeta[];
  // category?: ItemMeta[];
  // banners?: Banner[];

  user?: {
    reservations?: SearchResult<Reservation>;
    consignments?: SearchResult<Consignment>;
    consignmentItem?: ConsignmentItem;
    rentals?: SearchResult<Rental>;
    stylings?: SearchResult<Styling>;
    serviceRequests?: SearchResult<ServiceRequest>;
    orders?: SearchResult<Order>;
  };

  boards?: SearchResult<Board>;
  auth?: User;
  item?: Item;
  items?: SearchResult<Item>;
  itemOptions?: ItemOption[];
  itemPrices?: ItemPrice[];
  myorder?: MyOrder;
  reserveSlots?: ReserveSlot[];
}

interface MutationIF {
  initMobileDevice?: SimpleResult;
  // ! Join
  sendAuthKeyForFindPwd?: SimpleResult;
  checkJoinEmailExist?: SimpleResult;
  checkJoinPhoneExist?: SimpleResult;
  sendJoinPhoneAuth?: SimpleResult;
  checkJoinPhoneAuth?: SimpleResult;
  login?: SimpleResult;
  join?: SimpleResult;
  logout?: SimpleResult;

  // User
  user: {
    // User
    withdraw?: SimpleResult;
    saveCart?: SimpleResult;
    removeCarts?: SimpleResult;
    updateCartsSelected?: SimpleResult;
    // Order
    createOrder?: SimpleResult;
    checkOrderPaid?: SimpleResult;
    checkMyOrderPaid?: SimpleResult;
    // Reservation
    postReservation?: SimpleResult;
    putReservation?: SimpleResult;
    cancelReservation?: SimpleResult;
    // Consignment
    newConsignment?: SimpleResult;
    newReConsignment?: SimpleResult;
    saveConsignment?: SimpleResult;
    saveConsignmentItem?: SimpleResult;
    addConsignmentItem?: SimpleResult;
    removeConsignmentItem?: SimpleResult;
    removeConsignmentItemImage?: SimpleResult;
    requestConsignment?: SimpleResult;
    deleteConsignments?: SimpleResult;
    // Rental
    newRental?: SimpleResult;
    saveRental?: SimpleResult;
    removeRentalImage?: SimpleResult;
    removeRentalCompanyInfoImage?: SimpleResult;
    requestRental?: SimpleResult;
    deleteRentals?: SimpleResult;
    // Styling
    newStyling?: SimpleResult;
    saveStyling?: SimpleResult;
    requestStyling?: SimpleResult;
    deleteStylings?: SimpleResult;
  };
}

export const gqlapi = {
  // Auth
  login: (email: string, pwd: string): Promise<SimpleResult | undefined> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation LogIn($email: String, $pwd: String) {
            login(email: $email, pwd: $pwd) {
              ok
              value
              expired
              msg
              # id
              # email
              # name
              # phone
              # isEmailAuthorized
              # isPhoneAuthorized
              # agreeSMSDate
              # agreeEmailDate
              # sessionKey
              # sessionExpired
              # zipcode
              # addr1
              # addr2
            }
          }
        `,
        variables: { email: email.trim(), pwd: pwd.trim() },
      })
      .then(result => {
        if (result.data?.login) return convertSimpleResult(result.data.login);
      });
  },
  sendAuthKeyForFindPwd: (email: string, phone: string) =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SendAuthKeyForFindPwd($email: String, $phone: String) {
            sendAuthKeyForFindPwd(email: $email, phone: $phone) {
              ok
              value
              code
            }
          }
        `,
        variables: { email, phone },
      })
      .then(result => {
        if (result.data?.sendAuthKeyForFindPwd) return result.data.sendAuthKeyForFindPwd;
        else throw new Error("Err");
      }),

  putUserProfile,
  toggleFavorite,
  // ! WithDraw
  withdraw: (email: string, pwd: string): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation Withdraw($email: String, $pwd: String) {
            user {
              withdraw(email: $email, pwd: $pwd) {
                ok
                count
                msg
                errtype
              }
            }
          }
        `,
        variables: { email, pwd },
      })
      .then(result => {
        if (result.data?.user?.withdraw) return convertSimpleResult(result.data.user.withdraw) ?? {};
        throw new Error("Error");
      });
  },
  getConstants: (): Promise<QueryIF> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetConstatns {
            constants {
              id
              key
              value
            }
            banners {
              id
              show
              msg
              url
              onlyShowNonLogin
            }
            category {
              id
              type
              key
              name
              parent
              desc
              seq
            }
            brand {
              id
              type
              key
              name
              parent
              desc
              seq
            }
            designer {
              id
              type
              key
              name
              parent
              desc
              seq
            }
          }
        `,
      })
      .then(result => {
        const ret: QueryIF = {};
        if (result.data.constants)
          ret.constants = result.data.constants.map(r => {
            const ret = convertConstant(r);
            return ret ? ret : {};
          });
        if (result.data.banners)
          ret.banners = result.data.banners.map(r => {
            const ret = convertBanner(r);
            return ret ? ret : {};
          });

        if (result.data.brand) ret.brand = result.data.brand.map(im => convertItemMeta(im) ?? {});
        if (result.data.designer) ret.designer = result.data.designer.map(im => convertItemMeta(im) ?? {});
        if (result.data.category) ret.category = result.data.category.map(im => convertItemMeta(im) ?? {});
        return ret;
      });
  },
  initMobileDevice: (os: string, version: string, fcm: string) => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation InitMobileDevice($os: String, $version: String, $fcm: String) {
            initMobileDevice(os: $os, version: $version, fcm: $fcm) {
              ok
              count
            }
          }
        `,
        variables: { os, version, fcm },
      })
      .then(result => {
        if (result.data?.initMobileDevice) return result.data.initMobileDevice;
        else throw new Error("Err");
      });
  },
  getServiceRequests: (sopt: ServiceRequestSearchOption): Promise<SearchResult<ServiceRequest>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query getServiceRequests($sopt: ServiceRequestSearchInput) {
            user {
              serviceRequests(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  created
                  updated
                  type
                  consignment {
                    id
                    status
                    created
                    delivery_zipcode
                    delivery_addr1
                    delivery_addr2
                    account_bank
                    account_number
                    account_name
                    user {
                      name
                      phone
                    }
                    items {
                      id
                      status
                      brand
                      designer
                      count
                      category
                      yom
                      pop
                      origin_price
                      expect_price
                      warranty
                      desc
                      images {
                        id
                        url
                      }
                    }
                  }
                  rental {
                    id
                    status
                    created
                    budget
                    rental_start
                    rental_end
                    days
                    receipt_target
                    receipt_number

                    user {
                      name
                      phone
                    }
                    images {
                      id
                      url
                    }
                    companyImages {
                      id
                      url
                    }
                  }
                  styling {
                    id
                    status
                    created
                    zipcode
                    addr1
                    addr2
                    areasize
                    areatype
                    expect_month
                    budget
                    request_detail
                    user {
                      name
                      phone
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then(result => {
        if (result.data.user?.serviceRequests)
          return {
            ...result.data.user.serviceRequests,
          };
        else throw new Error("Err");
      });
  },
  getAlertInfos: alertInfos,
  getUserNotis,
  // Consignment
  newConsignment: (): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation NewConsignment {
            user {
              newConsignment {
                ok
                count
                id
              }
            }
          }
        `,
      })
      .then(result => {
        if (result.data?.user?.newConsignment) return result.data.user.newConsignment;
        throw new Error("Err");
      }),

  newReConsignment: (order_id: number, order_item_ids: number[]): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation NewReConsignment($order_id: BigInt, $order_item_ids: [BigInt]) {
            user {
              newReConsignment(order_id: $order_id, order_item_ids: $order_item_ids) {
                ok
                count
                id
                errtype
              }
            }
          }
        `,
        variables: { order_id, order_item_ids },
      })
      .then(result => {
        if (result.data?.user?.newReConsignment) return result.data.user.newReConsignment;
        throw new Error("Err");
      }),
  getConsignments: (sopt: ConsignmentSearchOption): Promise<SearchResult<Consignment>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetConsignments($sopt: ConsignmentSearchInput) {
            user {
              consignments(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  user_id
                  created
                  updated
                  status
                  request_detail
                  delivery_zipcode
                  delivery_addr1
                  delivery_addr2

                  account_bank
                  account_number
                  account_name
                  items {
                    id
                    consignment_id
                    status
                    category
                    brand
                    designer
                    origin_price
                    expect_price
                    count
                    yom
                    pop
                    warranty
                    desc
                    images {
                      id
                      url
                    }
                    order_item_id
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then(result => {
        if (result.data.user?.consignments)
          return {
            ...result.data.user.consignments,
            list: result.data.user.consignments.list?.map(r => convertConsignment(r) ?? {}) ?? [],
          };
        else throw new Error("Err");
      });
  },
  saveConsignment: (consignment: Consignment): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveConsignment($consignment: ConsignmentInput) {
            user {
              saveConsignment(consignment: $consignment) {
                ok
                count
                id
                code
              }
            }
          }
        `,
        variables: { consignment },
      })
      .then(result => {
        if (result.data?.user.saveConsignment) return result.data.user.saveConsignment;
        throw new Error("Err");
      });
  },
  saveConsignmentItem: (consignmentItem: ConsignmentItem): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveConsignmentItem($consignmentItem: ConsignmentItemInput) {
            user {
              saveConsignmentItem(consignmentItem: $consignmentItem) {
                ok
                id
                count
                msg
              }
            }
          }
        `,
        variables: { consignmentItem },
      })
      .then(result => {
        if (result.data?.user.saveConsignmentItem) return result.data?.user.saveConsignmentItem;
        throw new Error("Err");
      });
  },
  addConsignmentItem: (consignment_id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation AddConsignmentItem($consignment_id: BigInt) {
            user {
              addConsignmentItem(consignment_id: $consignment_id) {
                ok
                count
                id
              }
            }
          }
        `,
        variables: { consignment_id },
      })
      .then(result => {
        if (result.data?.user.addConsignmentItem) return result.data.user.addConsignmentItem;
        throw new Error("Err");
      });
  },
  removeConsignmentItem: (id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveConsignmentItem($id: BigInt) {
            user {
              removeConsignmentItem(consignmentItemId: $id) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { id },
      })
      .then(result => {
        if (result.data?.user.removeConsignmentItem) return result.data?.user.removeConsignmentItem;
        throw new Error("Err");
      });
  },
  removeConsignmentItemImage: (id: number, image_id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveConsignmentItemImage($id: BigInt, $image_id: BigInt) {
            user {
              removeConsignmentItemImage(consignmentItemId: $id, image_id: $image_id) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { id, image_id },
      })
      .then(result => {
        if (result.data?.user.removeConsignmentItemImage) return result.data?.user.removeConsignmentItemImage;
        throw new Error("Err");
      });
  },
  getConsignmentItem: (id: number): Promise<ConsignmentItem> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetConsignmentItem($id: BigInt) {
            user {
              consignmentItem(id: $id) {
                id
                consignment_id
                status
                count
                origin_price
                expect_price
                category
                designer
                brand
                yom
                pop
                warranty
                desc
                images {
                  id
                  url
                }
              }
            }
          }
        `,
        variables: { id },
      })
      .then(result => {
        if (result?.data?.user?.consignmentItem) return convertConsignmentItem(result.data.user.consignmentItem) ?? {};
        else throw new Error("Err");
      });
  },
  requestConsignment: (consignment_id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RequestConsignment($consignment_id: BigInt) {
            user {
              requestConsignment(consignment_id: $consignment_id) {
                ok
                count
                code
                msg
              }
            }
          }
        `,
        variables: { consignment_id },
      })
      .then(result => {
        if (result.data?.user?.requestConsignment) return result.data.user.requestConsignment;
        throw new Error("Err");
      });
  },
  deleteConsignments: (consignment_ids: number[]): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DeleteConsignments($consignment_ids: [BigInt]) {
            user {
              deleteConsignments(consignment_ids: $consignment_ids) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { consignment_ids },
      })
      .then(result => {
        if (result.data?.user?.deleteConsignments) return result.data.user.deleteConsignments;
        throw new Error("Err");
      });
  },

  // Rental
  newRental: (): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation NewRental {
            user {
              newRental {
                ok
                count
                id
              }
            }
          }
        `,
      })
      .then(result => {
        if (result.data?.user.newRental) return result.data.user.newRental;
        throw new Error("Err");
      }),
  saveRental: (rental: Rental): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveRental($rental: RentalInput) {
            user {
              saveRental(rental: $rental) {
                ok
                id
                count
                msg
                code
              }
            }
          }
        `,
        variables: { rental },
      })
      .then(result => {
        if (result.data?.user.saveRental) return result.data?.user.saveRental;
        else throw new Error("Err");
      }),
  removeRentalImage: (rental_id: number, image_id: number): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveRentalImage($rental_id: BigInt, $image_id: BigInt) {
            user {
              removeRentalImage(rental_id: $rental_id, image_id: $image_id) {
                ok
                count
                code
                msg
              }
            }
          }
        `,
        variables: { rental_id, image_id },
      })
      .then(result => {
        if (result?.data?.user?.removeRentalImage) return result.data.user.removeRentalImage;
        else throw new Error("Err");
      }),
  removeRentalCompanyInfoImage: (rental_id: number, image_id: number): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveRentalCompanyInfoImage($rental_id: BigInt, $image_id: BigInt) {
            user {
              removeRentalCompanyInfoImage(rental_id: $rental_id, image_id: $image_id) {
                ok
                count
                code
                msg
              }
            }
          }
        `,
        variables: { rental_id, image_id },
      })
      .then(result => {
        if (result?.data?.user?.removeRentalCompanyInfoImage) return result.data.user.removeRentalCompanyInfoImage;
        else throw new Error("Err");
      }),
  requestRental: (rental_id: number): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RequestRental($rental_id: BigInt) {
            user {
              requestRental(rental_id: $rental_id) {
                ok
                code
                msg
              }
            }
          }
        `,
        variables: { rental_id },
      })
      .then(result => {
        if (result.data?.user?.requestRental) return result.data?.user?.requestRental;
        throw new Error("Err");
      }),
  // TODO add more columns
  getRentals: (sopt: RentalSearchOption) =>
    client
      .query<QueryIF>({
        query: gql`
          query GetRentals($sopt: RentalSearchInput) {
            user {
              rentals(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  user_id
                  created
                  updated
                  status
                  rental_start
                  rental_end
                  days
                  budget
                  receipt_target
                  receipt_number
                  request_detail
                  user {
                    name
                    phone
                  }
                  images {
                    id
                    url
                  }
                  companyImages {
                    id
                    url
                  }
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then(result => {
        if (result.data.user?.rentals)
          return { ...result.data.user.rentals, list: result.data.user.rentals.list?.map(r => convertRental(r) ?? r) };
        throw new Error("Err");
      }),
  deleteRentals: (rental_ids: number[]): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DeleteRentals($rental_ids: [BigInt]) {
            user {
              deleteRentals(rental_ids: $rental_ids) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { rental_ids },
      })
      .then(result => {
        if (result.data?.user?.deleteRentals) return result.data.user.deleteRentals;
        throw new Error("Err");
      });
  },

  // Styling
  newStyling: (): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation NewStyling {
            user {
              newStyling {
                ok
                count
                id
              }
            }
          }
        `,
      })
      .then(result => {
        if (result.data?.user.newStyling) return result.data.user.newStyling;
        throw new Error("Err");
      }),
  saveStyling: (styling: Styling): Promise<SimpleResult> =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveStyling($styling: StylingInput) {
            user {
              saveStyling(styling: $styling) {
                ok
                count
                id
              }
            }
          }
        `,
        variables: { styling },
      })
      .then(result => {
        if (result.data?.user?.saveStyling) return result.data?.user.saveStyling;
        throw new Error("Err");
      }),
  requestStyling: (styling_id: number) =>
    client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RequestStyling($styling_id: BigInt) {
            user {
              requestStyling(styling_id: $styling_id) {
                ok
                count
                id
                msg
              }
            }
          }
        `,
        variables: { styling_id },
      })
      .then(result => {
        if (result.data?.user.requestStyling) return result.data.user.requestStyling;
        else throw new Error("Err");
      }),
  // TODO add more columns
  getStylings: (sopt: StylingSearchOption) =>
    client
      .query<QueryIF>({
        query: gql`
          query GetStylings($sopt: StylingSearchInput) {
            user {
              stylings(sopt: $sopt) {
                total
                offset
                limit
                list {
                  id
                  created
                  updated
                  status
                  areatype
                  areasize
                  zipcode
                  addr1
                  addr2
                  expect_month
                  budget
                  request_detail
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then(result => {
        if (result.data.user?.stylings)
          return {
            ...result.data.user.stylings,
            list: result.data.user.stylings.list?.map(r => convertStyling(r) ?? r),
          };
        throw new Error("Err");
      }),
  deleteStylings: (styling_ids: number[]): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation DeleteStylings($styling_ids: [BigInt]) {
            user {
              deleteStylings(styling_ids: $styling_ids) {
                ok
                count
                msg
              }
            }
          }
        `,
        variables: { styling_ids },
      })
      .then(result => {
        if (result.data?.user?.deleteStylings) return result.data.user.deleteStylings;
        throw new Error("Err");
      });
  },
  setUserAlertInfo,
  markReadUserNoti,
  delUserNoti,
  delAllUserNoti,
  getUnreadUserNotiCount,
  getInitialData: (): Promise<User | undefined> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetInitialData {
            auth {
              id
              email
              name
              phone
              isEmailAuthorized
              isPhoneAuthorized
              agreeSMSDate
              agreeEmailDate
              sessionKey
              sessionExpired
              zipcode
              addr1
              addr2

              num_order_done_price
              num_unread_noti

              favorites
              carts {
                id
                item_id
                item_option_id
                count
                selected
                item {
                  id
                  visible
                  status
                  priceOpen
                  price
                  vatAdded
                  name
                  description
                  topImages {
                    id
                    name
                    url
                    type
                  }
                }
                itemOption {
                  id
                  name
                  stock_avail
                }
              }
              notis {
                id
                created
                readed
                title
                body
                imageUrl
                dataUrl
              }
            }
          }
        `,
      })
      .then(result => convertUser(result?.data?.auth));
  },
  getCarts: (): Promise<Cart[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetCarts {
            auth {
              carts {
                id
                item_id
                item_option_id
                count
                selected
                item {
                  id
                  visible
                  status
                  priceOpen
                  price
                  vatAdded
                  name
                  description
                  topImages {
                    id
                    name
                    url
                    type
                  }
                }
                itemOption {
                  id
                  name
                  stock_avail
                }
              }
            }
          }
        `,
      })
      .then(result => {
        if (result?.data?.auth?.carts) return result.data.auth?.carts;
        else throw new Error("Err");
      });
  },

  getItems: (sopt: ItemSearchOption): Promise<SearchResult<Item>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItemList($sopt: ItemSearchInput) {
            items(sopt: $sopt) {
              total
              offset
              limit
              list {
                id
                name
                price
                priceBefore
                discountRate
                priceOpen
                vatAdded
                status
                visible
                defaultItemOptionId
                category_id
                category_ids
                brand_id
                designer_id

                isLabelConsignment
                isLabelSpecialPrice
                isLabelLastOne

                topImages {
                  id
                  url
                }
                itemOption {
                  id
                  stock_avail
                }
                itemOptions {
                  id
                  stock_avail
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then(result => {
        if (result.data.items)
          return {
            ...result.data.items,
            list: result.data.items.list
              ? result.data.items.list.map(r => {
                  const ret = convertItem(r);
                  return ret ? ret : {};
                })
              : [],
          };
        else throw new Error("Err");
      });
  },
  getItem: (itemId: number): Promise<Item> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItemInfo($itemId: BigInt) {
            item(itemId: $itemId) {
              id
              visible
              status
              priceOpen
              price
              priceBefore
              vatAdded
              defaultItemOptionId
              discountRate

              sh_hashtag
              sh_itemoptions
              sh_desc

              name
              description
              hashtag
              category_id
              category_ids
              brand_id
              designer_id

              properties {
                name
                value
              }

              topImages {
                id
                url
              }

              itemOption {
                id
                name
                stock_avail
              }
              itemOptions {
                id
                name
                stock_avail
              }
            }
          }
        `,
        variables: { itemId: itemId },
      })
      .then(result => {
        if (result.data.item) {
          const ret = convertItem(result.data.item);
          return ret ? ret : {};
        } else throw new Error("Err");
      });
  },
  getItemOptions: (itemOptionIds: number[]): Promise<ItemOption[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItemOptions($itemOptionIds: [Int]) {
            itemOptions(itemOptionIds: $itemOptionIds) {
              id
              itemId
              name
              status
              created
              stock_avail
            }
          }
        `,
        variables: { itemOptionIds: itemOptionIds },
      })
      .then(result => {
        if (result.data.itemOptions)
          return result.data.itemOptions.map(r => {
            const ret = convertItemOption(r);
            return ret ? ret : {};
          });
        else throw new Error("Err");
      });
  },
  getItemPrices: (itemIds: number[]): Promise<ItemPrice[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetItemPrices($itemIds: [BigInt]) {
            itemPrices(itemIds: $itemIds) {
              id
              priceOpen
              price
              priceBefore
              vatAdded
              discountRate
            }
          }
        `,
        variables: { itemIds },
      })
      .then(result => {
        if (result.data.itemPrices) return result.data.itemPrices.map(r => convertItemPrice(r) ?? {});
        else throw new Error("Err");
      });
  },

  saveCart: (cart: Cart): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation SaveCart($cart: CartInput) {
            user {
              saveCart(cart: $cart) {
                ok
                count
              }
            }
          }
        `,
        variables: { cart: cart },
      })
      .then(result => {
        if (result.data?.user?.saveCart) return result.data.user.saveCart;
        else throw new Error("Err");
      });
  },
  removeCarts: (cartIds: number[]): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveCarts($ids: [BigInt]) {
            user {
              removeCarts(ids: $ids) {
                ok
                count
              }
            }
          }
        `,
        variables: { ids: cartIds },
      })
      .then(result => {
        if (result.data?.user?.removeCarts) return result.data?.user?.removeCarts;
        else throw new Error("Err");
      });
  },
  updateCartsSelected: (cartIds: number[], selected: boolean): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation RemoveCarts($ids: [BigInt], $selected: Boolean) {
            user {
              updateCartsSelected(ids: $ids, selected: $selected) {
                ok
                count
              }
            }
          }
        `,
        variables: { ids: cartIds, selected: selected },
      })
      .then(result => {
        if (result.data?.user?.updateCartsSelected) return result.data?.user?.updateCartsSelected;
        else throw new Error("Err");
      });
  },
  getFavorites: (): Promise<number[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query getFavorites {
            auth {
              favorites
            }
          }
        `,
      })
      .then(result => {
        if (result.data.auth?.favorites) return result.data.auth.favorites;
        else throw new Error("Err");
      });
  },
  getOrders: (sopt: OrderSearchOption): Promise<SearchResult<Order>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetOrders($sopt: OrderSearchInput) {
            user {
              orders(sopt: $sopt) {
                limit
                offset
                total
                list {
                  id
                  useremail
                  user_id
                  m_id
                  addr_zipcode
                  addr_addr1
                  addr_addr2
                  addr_phone
                  addr_username

                  deliver_msg
                  price
                  status

                  created
                  ordered
                  updated

                  paytype
                  cash_receipt
                  receipt_target
                  receipt_number

                  direct_name
                  credit_card_number
                  credit_approved_id

                  orderItems {
                    id
                    item_id
                    item_option_id
                    count
                    item {
                      id
                      price
                      priceOpen
                      type
                      visible
                      vatAdded
                      name
                      search_text
                      brand_id
                      category_id
                      designer_id
                      status
                      topImages {
                        id
                        uid
                        url
                      }
                      description
                    }
                    itemOption {
                      id
                      name
                      stock_avail
                    }
                    consignment_item_id
                  }
                  orderedItems {
                    id
                    itemId
                  }
                }
              }
            }
          }
        `,
        variables: { sopt: sopt },
      })
      .then(result => {
        if (result.data.user?.orders)
          return {
            ...result.data.user?.orders,
            list: result.data.user?.orders.list
              ? result.data.user?.orders.list.map(o => {
                  const ret = convertOrder(o);
                  if (ret) return ret;
                  else return {};
                })
              : [],
          };
        else throw new Error("Err");
      });
  },
  getMyOrderStatistics: () => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetMyOrder {
            myorder {
              beforePay
              ordered
              preparing
              delivering
              done
              returning
              changing
              cancelling
            }
          }
        `,
      })
      .then(result => convertMyOrder(result.data.myorder));
  },
  // ! Join Area Start
  checkJoinEmailExist: (email: string) => {
    const CHECK_JOIN_EMAIL_EXIST = gql`
      mutation CheckJoinEmailExist($email: String) {
        checkJoinEmailExist(email: $email) {
          ok
        }
      }
    `;
    return client.mutate<MutationIF>({ mutation: CHECK_JOIN_EMAIL_EXIST, variables: { email: email } }).then(result => {
      if (result.data?.checkJoinEmailExist) return result.data?.checkJoinEmailExist;
      else throw new Error("Err");
    });
  },
  checkJoinPhoneExist: (phone: string): Promise<SimpleResult> => {
    const CHECK_JOIN_PHONE_EXIST = gql`
      mutation CheckJoinPhoneExist($phone: String) {
        checkJoinPhoneExist(phone: $phone) {
          ok
        }
      }
    `;
    return client.mutate<MutationIF>({ mutation: CHECK_JOIN_PHONE_EXIST, variables: { phone: phone } }).then(result => {
      if (result.data?.checkJoinPhoneExist) return result.data.checkJoinPhoneExist;
      else throw new Error("Err");
    });
  },
  sendJoinPhoneAuth: (phone: string) => {
    const SEND_JOIN_PHONE_AUTH = gql`
      mutation SendJoinPhoneExist($phone: String) {
        sendJoinPhoneAuth(phone: $phone) {
          ok
          value
        }
      }
    `;
    return client.mutate<MutationIF>({ mutation: SEND_JOIN_PHONE_AUTH, variables: { phone: phone } }).then(result => {
      if (result.data?.sendJoinPhoneAuth) return result.data.sendJoinPhoneAuth;
      else throw new Error("Err");
    });
  },
  checkJoinPhoneAuth: (phone: string, skey: string, nkey: string) => {
    const CHECK_JOIN_PHONE_AUTH = gql`
      mutation CheckJoinPhoneAuth($phone: String, $skey: String, $nkey: String) {
        checkJoinPhoneAuth(phone: $phone, skey: $skey, nkey: $nkey) {
          ok
          msg
        }
      }
    `;
    return client
      .mutate<MutationIF>({
        mutation: CHECK_JOIN_PHONE_AUTH,
        variables: {
          phone: phone,
          skey: skey,
          nkey: nkey,
        },
      })
      .then(result => {
        if (result.data?.checkJoinPhoneAuth) return result.data.checkJoinPhoneAuth;
        else throw new Error("Err");
      });
  },
  join: (user: User) => {
    const JOIN = gql`
      mutation Join($user: UserInput) {
        join(user: $user) {
          ok
          value
          msg
        }
      }
    `;
    return client.mutate<MutationIF>({ mutation: JOIN, variables: { user: user } }).then(result => {
      if (result.data?.join) return result.data.join;
      else throw new Error("Err");
    });
  },
  // ! Join Area End
  logout: () => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation Logout {
            logout {
              ok
              count
            }
          }
        `,
      })
      .then(result => {
        if (result.data?.logout) return result.data.logout;
        else throw new Error("Err");
      });
  },
  createOrder: (order: Order) => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation CreateOrder($order: OrderInput) {
            user {
              createOrder(order: $order) {
                ok
                id
                count
                msg
                value
                target
                amount
              }
            }
          }
        `,
        variables: { order: order },
      })
      .then(result => {
        if (result.data?.user?.createOrder) return result.data.user.createOrder;
        else throw new Error("Err");
      });
  },
  checkOrderPaid: (m_id: string) => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation CheckOrderPaid($m_id: String) {
            user {
              checkOrderPaid(m_id: $m_id) {
                ok
                id
                value
                count
                msg
                target
                amount
              }
            }
          }
        `,
        variables: { m_id: m_id },
      })
      .then(result => {
        if (result.data?.user?.checkOrderPaid) return result.data?.user?.checkOrderPaid;
        else throw new Error("Err");
      });
  },
  checkMyOrderPaid: () => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation CheckOrderPaid {
            user {
              checkMyOrderPaid {
                ok
                id
                value
                count
                msg
                target
                amount
              }
            }
          }
        `,
      })
      .then(result => {
        if (result.data?.user?.checkMyOrderPaid) return result.data?.user?.checkMyOrderPaid;
        else throw new Error("Err");
      });
  },
  getReserveSlots: (sopt: ReserveSlotSearchOption): Promise<ReserveSlot[]> => {
    return client
      .query<QueryIF>({
        query: gql`
          query getReserveSlots($sopt: ReserveSlotSearchInput) {
            reserveSlots(sopt: $sopt) {
              id
              date
              time
              place
              created
              show
              status
              count
              reserved
            }
          }
        `,
        variables: { sopt },
      })
      .then(result => {
        if (result.data.reserveSlots)
          return result.data.reserveSlots
            .map(o => {
              const ret = convertReserveSlot(o);
              return ret ? ret : {};
            })
            .filter(o => o.date);
        else return [];
      });
  },
  postReservation: (reservation: Reservation): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation PostReservation($reservation: ReservationInput) {
            user {
              postReservation(reservation: $reservation) {
                ok
                id
                msg
                code
              }
            }
          }
        `,
        variables: { reservation },
      })
      .then(result => {
        if (result.data?.user.postReservation) return result.data.user.postReservation;
        else throw new Error("Err");
      });
  },
  putReservation: (reservation: Reservation): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation PutReservation($reservation: ReservationInput) {
            user {
              putReservation(reservation: $reservation) {
                ok
                id
              }
            }
          }
        `,
        variables: { reservation },
      })
      .then(result => {
        if (result.data?.user.putReservation) return result.data.user.putReservation;
        else throw new Error("Err");
      });
  },
  getUserReservations: (sopt: ReservationSearchOption): Promise<SearchResult<Reservation>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetMyReservations($sopt: ReservationSearchInput) {
            user {
              reservations(sopt: $sopt) {
                offset
                limit
                total
                list {
                  id
                  created
                  user_id
                  email
                  name
                  phone
                  date
                  time
                  place
                  status
                  memberCount
                  memo
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then(result => {
        if (result.data.user?.reservations)
          return {
            ...result.data.user.reservations,
            list: result.data.user.reservations.list?.map(r => convertReservation(r) ?? {}),
          };
        else throw new Error("Err");
      });
  },
  cancelReservation: (id: number): Promise<SimpleResult> => {
    return client
      .mutate<MutationIF>({
        mutation: gql`
          mutation CancelReservation($id: BigInt) {
            user {
              cancelReservation(id: $id) {
                ok
                count
              }
            }
          }
        `,
        variables: { id: id },
      })
      .then(result => {
        if (result.data?.user.cancelReservation) return result.data.user.cancelReservation;
        else throw new Error("Err");
      });
  },
  getBoards: (sopt: BoardSearchOption): Promise<SearchResult<Board>> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetBoards($sopt: BoardSearchInput) {
            boards(sopt: $sopt) {
              total
              offset
              limit
              list {
                id
                title
                subtitle
                period
                thumb_image {
                  id
                  url
                }
              }
            }
          }
        `,
        variables: { sopt },
      })
      .then(result => {
        if (result.data.boards)
          return { ...result.data.boards, list: (result.data.boards.list ?? []).map(r => convertBoard(r) ?? {}) };
        throw new Error("Err");
      });
  },
  getBoardDetail: (id: number): Promise<Board> => {
    return client
      .query<QueryIF>({
        query: gql`
          query GetBoardDetail($sopt: BoardSearchInput) {
            boards(sopt: $sopt) {
              list {
                id
                title
                subtitle
                period
                thumb_image {
                  id
                  url
                }
                blocks {
                  id
                  seq
                  type
                  body
                  binded_images {
                    id
                    url
                  }
                }
              }
            }
          }
        `,
        variables: { sopt: { id } },
      })
      .then(result => {
        if (result.data.boards?.list && result.data.boards.list.length > 0) {
          const r = convertBoard(result.data.boards.list[0]) ?? {};
          r.blocks = (r.blocks ?? []).map(b => convertBoardBlock(b) ?? {});
          return r;
        }
        throw new Error("Err");
      });
  },
};
