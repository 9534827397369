import { atom } from "recoil";
import { getRNOS } from "../../api";

interface GlobalStateIF {
  playImageSide: boolean;
  showAppSuggestionOnMW: boolean;
  dimmed: boolean;
}

const INIT_GLOBAL_STATE: GlobalStateIF = {
  playImageSide: true,
  showAppSuggestionOnMW: getRNOS() ? false : true,
  dimmed: false,
};

export const GlobalState = atom<GlobalStateIF>({
  key: "GlobalState",
  default: INIT_GLOBAL_STATE,
});
