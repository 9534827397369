import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { HeaderState } from "../../../store/states/HeaderState";
import { Banner } from "../../../types/elements";
import BannerBar from "./BannerBar";
import BottomNav from "./BottomNav";
import LeftMenu from "./leftmenu/LeftMenu";
import NavBar from "./NavBar";
import RightCart from "./RightCart";
import SearchLayer from "./SearchLayer";
import { useGlobal } from "../../../hooks/useGlobal";
import { useWindowSize } from "../../../hooks/useWindowSize";

interface HeaderProps {
  banners: Banner[];
}
const Header: React.FC<HeaderProps> = props => {
  const navi = useNavigate();
  const { isMobile } = useWindowSize();
  const [openLeftMenu, setOpenLeftMenu] = useState<boolean>(false);
  const [openRightCart, setOpenRightCart] = useState<boolean>(false);
  const [headerState, setHeaderState] = useRecoilState(HeaderState);
  const { dimmed } = useGlobal();

  return (
    <div className="a-header">
      {props.banners.length > 0 && <BannerBar banners={props.banners} />}
      <BottomNav onClickMenu={() => setOpenLeftMenu(true)} />
      <NavBar
        onClickMenu={() => setOpenLeftMenu(true)}
        onClickCart={() => setOpenRightCart(true)}
        onClickCartPC={() => {
          navi("/cart");
          if (headerState.openSearchLayer) setHeaderState(hs => ({ ...hs, openSearchLayer: false }));
        }}
        onClickSearch={(open?: boolean) => setHeaderState(hs => ({ ...hs, openSearchLayer: open ?? true }))}
        onClickReserve={() => {
          navi("/reservation");
          if (headerState.openSearchLayer) setHeaderState(hs => ({ ...hs, openSearchLayer: false }));
        }}
        onClickMy={() => {
          navi("/mypage");
          if (headerState.openSearchLayer) setHeaderState(hs => ({ ...hs, openSearchLayer: false }));
        }}
      />
      <LeftMenu open={openLeftMenu} onClose={() => setOpenLeftMenu(false)} />
      <SearchLayer
        open={headerState.openSearchLayer}
        onClose={() => setHeaderState(hs => ({ ...hs, openSearchLayer: false }))}
      />
      <RightCart open={openRightCart} onClose={() => setOpenRightCart(false)} />
      <div
        className="dim"
        style={{ display: openLeftMenu || openRightCart || (dimmed && isMobile) ? undefined : "none" }}
        onClick={() => {
          if (openLeftMenu) setOpenLeftMenu(false);
          if (openRightCart) setOpenRightCart(false);
        }}></div>
    </div>
  );
};

export default Header;
