import { FCM_TOKEN, IS_RNAPP, RNOS, RNVer, SESSION_KEY } from "../types/elements";

export const Ls = {
  getItems: (key: LsTypes): Array<any> => {
    let items = window.localStorage.getItem(key);
    if (items === null) {
      items = "[]";
    }
    try {
      const parsed = JSON.parse(items);
      return parsed;
    } catch (err) {
      console.log("JSON parsing error", err);
      return [];
    }
  },
  setItems: (key: LsTypes, items: any[]) => {
    window.localStorage.setItem(key, JSON.stringify(items));
  },
  getItem: (key: LsTypes) => {
    let item = window.localStorage.getItem(key);
    if (item === null) {
      item = "{}";
    }
    try {
      const parsed = JSON.parse(item);
      return parsed;
    } catch (err) {
      return {};
    }
  },
  setItem: (key: LsTypes, item: any) => {
    window.localStorage.setItem(key, JSON.stringify(item));
  },
};

export type LsTypes =
  | "MAINVIEW_NEW_ITEMS"
  | "ADDR"
  | "CARTITEMS"
  | "ORDERS"
  | "CONSTANTS"
  | "NEWORDERSTATE"
  | "TOP_CATEGORY_UPDATED"
  | "TOP_CATEGORY_ARRAY"
  | "CATEGORY_UPDATED"
  | "CATEGORY_ARRAY"
  | "BRAND_UPDATED"
  | "BRAND_ARRAY"
  | "DESIGNER_UPDATED"
  | "DESIGNER_ARRAY"
  | "STATIC_CUSTOM_SLIDE_1"
  | "STATIC_CUSTOM_SLIDE_3"
  | "CONSTANTS_BANNERS"
  | "CLOSED_BANNER_IDS";

export const getSessionKey = (): string | null => {
  const key = window.localStorage.getItem(SESSION_KEY);
  return key;
};

export const setSessionKey = (key: string) => {
  window.localStorage.setItem(SESSION_KEY, key);
};

export const getFcmToken = (): string | undefined => {
  const v = window.localStorage.getItem(FCM_TOKEN);
  return v === null ? undefined : v;
};

export const getIsRNAPP = (): boolean => {
  return window.localStorage.getItem(IS_RNAPP) === "true";
};

export const getRNOS = (): string | undefined => {
  const v = window.localStorage.getItem(RNOS);
  return v === null ? undefined : v;
};
export const getRNVer = (): string | undefined => {
  const v = window.localStorage.getItem(RNVer);
  return v === null ? undefined : v;
};
