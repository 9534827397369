import { atom, SetterOrUpdater } from "recoil";
import { UIData } from "../types";
import { User } from "../../types/elements";
import { AuthAPI, setSessionKey, ItemAPI } from "../../api";

import { gqlapi } from "../../graphql/gqlapi";

interface AuthStateIF extends UIData<User> {}

const INIT_AUTH_STATE: AuthStateIF = {
  loaded: false,
  status: "INITIALIZED",
  data: {},
};
export const AuthState = atom<AuthStateIF>({
  key: "AuthState",
  default: INIT_AUTH_STATE,
});

export const setAuth = async (get: AuthStateIF, set: SetterOrUpdater<AuthStateIF>, user: User) => {
  let state: AuthStateIF = { ...get };
  set((state = { ...state, data: user, loaded: true, status: "DONE" }));
};

export const getAuth = async (get: AuthStateIF, set: SetterOrUpdater<AuthStateIF>) => {
  let state: AuthStateIF = { ...get };
  if (get.status !== "REQUESTED") {
    set((state = { ...state, status: "REQUESTED" }));

    try {
      const authResult = await AuthAPI.getAuth();
      if (
        authResult &&
        authResult.data &&
        authResult.data.result &&
        authResult.data.result.user &&
        authResult.data.result.logged === true
      ) {
        set((state = { ...state, data: authResult.data.result.user, loaded: true, status: "DONE" }));
      } else {
        set((state = { ...state, data: {}, loaded: false, status: "DONE" }));
      }
      return state;
    } catch (err) {
      console.log("Error on getAuth");
      set((state = { ...state, loaded: false, status: "ERROR", data: {}, err: err }));
      throw err;
    }
  }
};

// export const login = async (
//   get: AuthStateIF,
//   set: SetterOrUpdater<AuthStateIF>,
//   email: string,
//   pwd: string
// ): Promise<User | undefined> => {
//   let state: AuthStateIF = { ...get };
//   set((state = { ...state, status: "REQUESTED" }));

//   try {
//     const result = await gqlapi.login(email, pwd);
//     set((state = { ...state, data: result ?? {}, loaded: result ? true : false, status: "DONE" }));
//     if (result?.sessionKey) setSessionKey(result.sessionKey);
//     return result;
//   } catch (err) {
//     console.log("Error on AuthAPI.postLogin", err);
//     set((state = { ...state, status: "ERROR", loaded: false, data: {}, err: err }));
//   }
// };

// export const logout = async (get: AuthStateIF, set: SetterOrUpdater<AuthStateIF>) => {
//   let state: AuthStateIF = { ...get };
//   set((state = { ...state, status: "REQUESTED" }));
//   try {
//     await AuthAPI.getLogout();
//     setSessionKey("");
//     set((state = { ...state, loaded: false, data: {}, status: "DONE" }));
//   } catch (err) {}
// };

export const addFavorite = async (get: AuthStateIF, set: SetterOrUpdater<AuthStateIF>, itemId: number) => {
  let state = { ...get };
  try {
    return await gqlapi.toggleFavorite(itemId, true).then(result => {
      if (result.ok) {
        const ufav = state && state.data && state.data.favorites ? state.data.favorites : [];
        if (ufav.indexOf(itemId) === -1) {
          set((state = { ...state, data: { ...state.data, favorites: [...ufav, itemId] } }));
        }
        return true;
      } else {
        return false;
      }
    });

    // const result = await ItemAPI.postFavorite(itemId);

    // if (result && result.data && result.data.updatedCount === 1) {
    //   const ufav = state && state.data && state.data.favorites ? state.data.favorites : [];
    //   if (ufav.indexOf(itemId) === -1) {
    //     set((state = { ...state, data: { ...state.data, favorites: [...ufav, itemId] } }));
    //   }
    //   return true;
    // } else {
    //   return false;
    // }
  } catch (err) {
    console.log("Error on AddFavorite ", err);
  }
};

export const removeFavorite = async (get: AuthStateIF, set: SetterOrUpdater<AuthStateIF>, itemId: number) => {
  let state = { ...get };
  try {
    return await gqlapi.toggleFavorite(itemId, false).then(result => {
      if (result.ok) {
        const ufav = state && state.data && state.data.favorites ? state.data.favorites : [];
        if (ufav.indexOf(itemId) !== -1) {
          set((state = { ...state, data: { ...state.data, favorites: [...ufav.filter(id => id !== itemId)] } }));
        }
        return true;
      } else {
        return false;
      }
    });
    // const result = await ItemAPI.deleteFavorite(itemId);
    // if (result && result.data && result.data.updatedCount === 1) {
    //   const ufav = state && state.data && state.data.favorites ? state.data.favorites : [];
    //   if (ufav.indexOf(itemId) !== -1) {
    //     set((state = { ...state, data: { ...state.data, favorites: [...ufav.filter(id => id !== itemId)] } }));
    //   }
    //   return true;
    // } else {
    //   return false;
    // }
  } catch (err) {
    console.log("Error on removeFavorite ", err);
  }
};
