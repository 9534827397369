import { atom } from "recoil";
import { Constant, Banner } from "../../types/elements";
import { Ls } from "../../api";

interface ConstantStateIF {
  constants: Array<Constant>;
  banners: Array<Banner>;
  closed_banner_ids: Array<number>;
}

const storedConstants = Ls.getItems("CONSTANTS");
const defaultConstants: Array<Constant> = [{ key: "STATIC_IMAGE_MAIN", value: "topimg.png" }];

const INIT_CONSTANT_STATE: ConstantStateIF = {
  constants: storedConstants.length === 0 ? defaultConstants : storedConstants,
  banners: Ls.getItems("CONSTANTS_BANNERS") ?? [],
  closed_banner_ids: [],
  // closed_banner_ids: Ls.getItems("CLOSED_BANNER_IDS") ?? [], // 배너 닫힘 상태저장
};

export const ConstantState = atom<ConstantStateIF>({
  key: "ConstantState",
  default: INIT_CONSTANT_STATE,
});
