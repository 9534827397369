import React from "react";

interface FooterProps {}
const Footer: React.FC<FooterProps> = props => {
  return (
    <div className="a-footer">
      <div className="a-footer__bg">
        <img src="/static/bottom.png" />
      </div>
      <div className="a-footer__contents">
        <ul className="a-footer__logo">
          <li>
            <img src="/static/logo/logo_serge.jpg" />
          </li>
          <li>
            <img src="/static/logo/logo_herman.jpg" />
          </li>
          <li>
            <img src="/static/logo/logo_usm.jpg" />
          </li>
          <li>
            <img src="/static/logo/logo_artemide.jpg" />
          </li>
          <li>
            <img src="/static/logo/logo_zanotta.jpg" />
          </li>
          <li>
            <img src="/static/logo/logo_tecno.jpg" />
          </li>
        </ul>
        <div className="a-footer__link">
          <a href="https://www.instagram.com/andersonc_design" target="_blank">
            인스타그램
          </a>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              alert("준비중 입니다.");
            }}>
            채용정보
          </a>
        </div>
        <div className="a-footer__info">
          <dl className="a-footer__info--detail">
            <dt>앤더슨씨 디자인 갤러리</dt>
            <dd>02 - 548 - 0865</dd>
            <dd>andersoncgallery@gmail.com</dd>
            <dd>서울 강남구 도산대로 55길 7 헤이븐빌딩</dd>
            <dd>오전 11시 - 오후 6시 (점심시간 1시 - 2시)</dd>
            <dd>사업자 등록번호 : 627-07-00731</dd>
          </dl>
        </div>
        <div className="a-footer__bottom">
          <a href="/">
            <img src="/static/adsc_logow3.png" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
