import { useRecoilState } from "recoil";
import { GlobalState } from "../store/states";

export const useGlobal = () => {
  const [globalState, setGlobalState] = useRecoilState(GlobalState);

  return {
    showAppSuggestionOnMW: globalState.showAppSuggestionOnMW,
    setShowAppSuggestionOnMW: (b: boolean) => {
      setGlobalState(gs => ({ ...gs, showAppSuggestionOnMW: b }));
    },
    playImageSide: globalState.playImageSide,
    setPlayImageSide: (b: boolean) => {
      setGlobalState(gs => ({ ...gs, playImageSide: b }));
    },
    dimmed: globalState.dimmed,
    setDimmed: (b: boolean) => {
      setGlobalState(gs => ({ ...gs, dimmed: b }));
    },
  };
};
