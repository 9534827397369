import React, { useEffect } from "react";

interface TitleFixProps {
  t?: string;
  elm?: React.ReactNode;
  children?: React.ReactNode;
}
const TitleFix: React.FC<TitleFixProps> = ({ t, elm, children }) => {
  useEffect(() => {
    const elem = document.querySelector("title");
    if (elem) elem.innerHTML = t ?? "앤더슨씨";
  }, [t]);
  return (
    <>
      {elm}
      {children}
    </>
  );
};

export default TitleFix;
