import React from "react";
import { useNavigate } from "react-router-dom";
import useCart from "../../../hooks/useCart";
import useSnack from "../../../hooks/useSnack";
import { getThumbUrl, numberWithCommas } from "../../../types/elements";

interface RightCartProps {
  open: boolean;
  onClose: () => void;
}
const RightCart: React.FC<RightCartProps> = props => {
  const navi = useNavigate();
  const { carts, updateCount, removeCart, updateSelected, count, totalPrice, itemPrice, vatPrice, placeOrder } =
    useCart();

  const handlePlaceOrder = () => {
    placeOrder({
      onClose: props.onClose,
      go: (path: string) => {
        navi(path);
      },
    });
  };

  return (
    <div id="ly_cart" className="a-layer-cart" style={{ display: props.open ? undefined : "none" }}>
      <div className="a-layer-cart__box">
        <strong className="a-layer-cart__title">SHOPPING CART</strong>
        <div className="a-layer-cart__scroll">
          <ul className="a-layer-cart__list">
            {carts.map((cart, idx, all) => {
              return (
                <li key={`cart_${cart.id}`}>
                  <div className="chk_box">
                    <span>{idx + 1}.</span>
                    <input
                      type="checkbox"
                      id="chk_agree"
                      // readOnly
                      checked={cart.selected}
                      onChange={(e: any) => cart && cart.id && updateSelected(cart.id, e.target.checked)}
                    />
                    <label htmlFor="chk_agree" className="a-product">
                      <img
                        src={
                          cart.item?.topImages && cart.item?.topImages.length > 0 && cart.item.topImages[0].url
                            ? "/" + getThumbUrl(cart.item.topImages[0].url)
                            : ""
                        }
                        alt=""
                      />
                      <span className="a-product__info">
                        <span className="a-product__name">{cart.item?.name}</span>
                        <span className="a-product__amount">
                          <button
                            type="button"
                            className="a-product__remove"
                            onClick={() =>
                              cart.id && (cart.count ?? 0) > 1 && updateCount(cart.id, (cart.count ?? 2) - 1)
                            }>
                            <span className="blind">수량 1씩 감소</span>
                          </button>
                          <input
                            type="number"
                            className="a-product__quantity"
                            aria-label="장바구니에 추가할 상품 수 1개"
                            pattern="\d*"
                            value={cart.count}
                            readOnly
                          />
                          <button
                            type="button"
                            className="a-product__add"
                            onClick={() => cart.id && updateCount(cart.id, (cart.count ?? 0) + 1)}>
                            <span className="blind">수량 1씩 추가</span>
                          </button>
                        </span>
                        <strong className="a-product__price">₩{numberWithCommas(cart.item?.price)}</strong>
                      </span>
                    </label>
                    <button
                      type="button"
                      className="a-product__delete"
                      onClick={() => cart && cart.id && removeCart(cart.id)}>
                      Remove
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="a-total">
            <dl>
              <div className="a-total__info">
                <dt>{count} items</dt>
                <dd>{numberWithCommas(itemPrice)} KRW</dd>
              </div>
              <div className="a-total__info">
                <dt>VAT</dt>
                <dd>{numberWithCommas(vatPrice)} KRW</dd>
              </div>
              <div className="a-total__info">
                <dt>Shipping</dt>
                <dd>FREE</dd>
              </div>
            </dl>
            <dl>
              <div className="a-total__price">
                <dt>Total</dt>
                <dd>{numberWithCommas(totalPrice)} KRW</dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="a-layer-cart__button">
          <button type="submit" className="a_btn btn_submit" onClick={handlePlaceOrder}>
            BUY NOW
          </button>
        </div>
        <button type="button" className="a-layer-cart__close" onClick={() => props.onClose()}>
          <span className="blind">닫기</span>
        </button>
      </div>
    </div>
  );
};

export default RightCart;
