import { atom } from "recoil";
import { Cart } from "../../types/elements";

interface CartStateIF {
  vatPrice: number;
  itemPrice: number;
  totalPrice: number;
  carts: Cart[];
  checkedAll: boolean;
}

const INIT_CART_STATE: CartStateIF = {
  vatPrice: 0,
  itemPrice: 0,
  totalPrice: 0,
  carts: [],
  checkedAll: false,
};

export const CartState = atom<CartStateIF>({
  key: "CartState",
  default: INIT_CART_STATE,
});
