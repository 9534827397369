import { atom } from "recoil";
import { whIF } from "../../types/elements";

export interface WindowResizeIF extends whIF {
  isMobile: boolean;
  initialized: boolean;
}

const INIT_WINDOW_RESIZE_STATE: WindowResizeIF = {
  width: 0,
  height: 0,
  isMobile: true,
  initialized: false,
};

export const WindowResizeState = atom<WindowResizeIF>({
  key: "WindowResizeState",
  default: INIT_WINDOW_RESIZE_STATE,
});
