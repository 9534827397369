// import Axios from "axios";
import { WrapperAxios as Axios } from "./base";

import {
  APIResponse,
  API_PATH,
  ReqPostLoginBody,
  ResPostLoginBody,
  ResGetAuthBody,
  User,
  AuthCodeCheckBody,
} from "../types/elements";

export const AuthAPI = {
  getAuth: (): Promise<APIResponse<ResGetAuthBody>> => {
    return Axios.get("/api" + API_PATH.AUTH);
  },
  postLogin: (body: ReqPostLoginBody): Promise<APIResponse<ResPostLoginBody>> => {
    return Axios.post("/api" + API_PATH.LOGIN, body);
  },
  getLogout: (): Promise<APIResponse<any>> => {
    return Axios.get("/api" + API_PATH.LOGOUT);
  },

  putUserProfile: (user: User): Promise<APIResponse<any>> => {
    return Axios.put("/api" + API_PATH.USER_PROFILE, user);
  },

  // putAddr: (body: Addr): Promise<APIResponse<any>> => {
  //   return Axios.put("/api" + API_PATH.ADDR, body);
  // },

  // JOIN
  // checkEmail
  postJoinEmailCheck: (body: AuthCodeCheckBody): Promise<APIResponse<any>> => {
    return Axios.post("/api" + API_PATH.JOIN_EMAIL_CHECK, body);
  },
  postJoinPhoneCheck: (body: AuthCodeCheckBody): Promise<APIResponse<any>> => {
    return Axios.post("/api" + API_PATH.JOIN_PHONE_CHECK, body);
  },
  postJoin: (body: { user: User }): Promise<APIResponse<any>> => {
    return Axios.post("/api" + API_PATH.JOIN, body);
  },

  // auth code Check
  postAuthSend: (body: AuthCodeCheckBody): Promise<APIResponse<AuthCodeCheckBody>> => {
    return Axios.post("/api/" + API_PATH.AUTH_SEND, body);
  },
  postAuthCheck: (body: AuthCodeCheckBody): Promise<APIResponse<AuthCodeCheckBody>> => {
    return Axios.post("/api/" + API_PATH.AUTH_CHECK, body);
  },

  // postPhoneAuthSend:

  // User update pwd
  putUserPwdUpdate: (body: AuthCodeCheckBody): Promise<APIResponse<any>> => {
    return Axios.put("/api" + API_PATH.USER_PWD_UPDATE, body);
  },
};
