import { useRecoilState } from "recoil";
import { gqlapi } from "../graphql/gqlapi";
import { AuthState, CartState, NewOrderState } from "../store/states";
import { Cart, SimpleResult } from "../types/elements";
import useSnack from "./useSnack";

let loaded = false;
const useCart = () => {
  const [cartState, setCartState] = useRecoilState(CartState);
  const [newOrderState, setNewOrderState] = useRecoilState(NewOrderState);
  const [authState, setAuthState] = useRecoilState(AuthState);
  const { openSnack } = useSnack();

  const clean = () => {
    setCartState({ ...cartState, carts: [], itemPrice: 0, vatPrice: 0, totalPrice: 0 });
  };

  const refresh = async () => {
    return gqlapi
      .getCarts()
      .then(async result => {
        if (loaded === false) {
          loaded = true;
          fixOneForOnlyOne(result).then(fixed => {
            if (fixed > 0) refresh();
          });
        }
        const checkedAll =
          result.length > 0 && result.map(o => (o.selected ? 0 : 1)).reduce((p: number, n: number) => p + n, 0) === 0
            ? true
            : false;
        const selected = result.filter(o => o.selected);
        const itemPrice = selected
          .map(o => (o.item?.price ? o.item.price * (o.count ? o.count : 0) : 0))
          .reduce((p: number, n: number) => p + n, 0);
        const vatPrice = selected
          .map(o => (o.item && o.item.price && o.item.vatAdded !== true && o.count ? o.item.price * o.count * 0.1 : 0))
          .reduce((p: number, n: number) => p + n, 0);

        const totalPrice = itemPrice + vatPrice;
        setCartState({ ...cartState, carts: result, checkedAll, itemPrice, vatPrice, totalPrice });
        return result;
      })
      .catch((err: any) => console.log("Error", err));
  };

  const fixOneForOnlyOne = async (carts?: Cart[]): Promise<number> => {
    if (carts) {
      const zeroCarts = carts.filter(cart => cart.count !== 0 && cart.itemOption?.stock_avail === 1);
      if (zeroCarts.length > 0) {
        const results = await Promise.all(
          zeroCarts.map(async cart => cart && cart.id && updateCount(cart.id, 1, true))
        );
        const updateNumber = results.reduce((p: number, n) => p + (n && n.count ? n.count : 0), 0);
        return updateNumber;
      }
    }
    return 0;
  };

  const saveCart = async (cart: Cart) => {
    return gqlapi
      .saveCart(cart)
      .then(result => {
        refresh();
        return result;
      })
      .catch(err => {
        return { ok: false, msg: err.message } as SimpleResult;
      });
  };

  const updateCount = async (cartId: number, count: number, woRefresh?: boolean): Promise<SimpleResult> => {
    console.log("updateCount", cartId, count);
    setCartState({
      ...cartState,
      carts: cartState.carts.map(o => {
        return { ...o, count: o.id === cartId ? count : o.count ? o.count : 0 };
      }),
    });
    return gqlapi.saveCart({ id: cartId, count: count }).then(result => {
      if (woRefresh) return result;
      return refresh().then(r => {
        return result;
      });
    });
  };

  const updateSelected = async (cartId: number, selected: boolean): Promise<SimpleResult> => {
    setCartState({
      ...cartState,
      carts: cartState.carts.map(o => {
        return { ...o, selected: o.id === cartId ? selected : o.selected };
      }),
    });
    return gqlapi.saveCart({ id: cartId, selected: selected }).then(result => {
      return refresh().then(r => {
        return result;
      });
    });
  };

  const removeCart = async (cartId: number) => {
    return gqlapi.removeCarts([cartId]).then(result => {
      return refresh().then(r => {
        return result;
      });
    });
  };

  const removeCarts = async (cartIds: number[]) => {
    return gqlapi.removeCarts(cartIds).then(result => {
      return refresh().then(r => {
        return result;
      });
    });
  };
  const updateAllSelected = async (cartIds: number[], selected: boolean) => {
    return gqlapi.updateCartsSelected(cartIds, selected).then(result => {
      return refresh().then(r => {
        return result;
      });
    });
  };

  const placeOrder = ({ onClose, go }: { onClose: () => void; go: (path: string) => void }) => {
    const selected = cartState.carts.filter(o => o.selected);
    console.log(selected);
    if (selected.length === 0) {
      openSnack({ msg: "선택된 항목이 없습니다." });
    }

    setNewOrderState({ ...newOrderState, carts: [...selected], orderDone: false, orderId: -1 });
    if (!!authState.data.id) {
      onClose();
      go("/neworder");
    } else {
      openSnack({
        msg: "로그인이 필요합니다. ",
        move: {
          name: "로그인",
          path: "/login",
          onClick: () => {
            onClose();
          },
        },
      });
    }
  };

  return {
    clean,
    refresh,
    saveCart,
    removeCart,
    removeCarts,
    updateCount,
    updateSelected,
    updateAllSelected,
    count: cartState.carts.length,
    carts: cartState.carts,
    totalPrice: cartState.totalPrice,
    itemPrice: cartState.itemPrice,
    vatPrice: cartState.vatPrice,
    placeOrder,
  };
};

export default useCart;
