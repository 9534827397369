import React, { MouseEvent, MouseEventHandler, SyntheticEvent, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CustomImage } from "../../../../types/elements";

interface PcMainSlideProps {
  width: number;
  customImages: CustomImage[];
  defaultImageUrl?: string;
  onClickImage?: (imageLink?: string) => any;
}
const PcMainSlide: React.FC<PcMainSlideProps> = props => {
  // 마우스 드래그일때도 클릭 이벤트가 무조건 실행되는것을 방지하기 위해
  const isMouseDownRef = useRef<number>();
  const mouseDownClientXRef = useRef<number>();
  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    isMouseDownRef.current = Date.now();
    mouseDownClientXRef.current = e.clientX;
  };
  const handleMouseUp = (ci: CustomImage) => (evt: MouseEvent<HTMLDivElement>) => {
    const now = Date.now();
    if (
      isMouseDownRef.current !== undefined && // 마우스 다운 시간이 설정되었고
      now - isMouseDownRef.current < 120 && // 마우스 다운이후 업 시간의 간격이 150ms 이하이고
      mouseDownClientXRef.current !== undefined && // 마우스 다운시 clientX가 설정되었고
      Math.abs(evt.clientX - mouseDownClientXRef.current) < 5 && // 마우스의 좌우 움직임이 5px 이하이고
      props.onClickImage // 실행할 핸들러가 존재하면
    ) {
      props.onClickImage(ci.image_link);
    }
    isMouseDownRef.current = undefined;
    mouseDownClientXRef.current = undefined;
  };

  return (
    <Slider className="slider main__slide" dots={true} autoplay={false} autoplaySpeed={2000}>
      {(props.customImages.length === 0
        ? ([{ image_path: props.defaultImageUrl }] as CustomImage[])
        : props.customImages
      ).map((ci, idx) => {
        return (
          <div
            key={`si${idx}`}
            className="main__slide--item"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp(ci)}>
            <span className="main__slide--img">
              {ci.image_path !== undefined && ci.image_path !== "" && <img src={"/" + ci.image_path} alt="" />}
            </span>
            <div className="main__slide--info">
              <strong className="main__slide--title">{ci.title_text}</strong>
              <p className="main__slide--dsc">{ci.desc_text}</p>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default PcMainSlide;
