import { RNWebViewPostMessage } from "../types/elements";
import { getIsRNAPP } from "./Ls";

export const RnAPI = {
  postMessage: (opt: RNWebViewPostMessage): boolean => {
    // console.log("opt", opt, getIsRNAPP());
    if (getIsRNAPP() && (window as any).ReactNativeWebView?.postMessage) {
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(opt));
      return true;
    }
    return false;
  },
};
