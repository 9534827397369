import { atom } from "recoil";

interface HeaderStateIF {
  openSearchLayer: boolean;
}

const INIT_HEADER_STATE: HeaderStateIF = {
  openSearchLayer: false,
};
export const HeaderState = atom<HeaderStateIF>({
  key: "HeaderState",
  default: INIT_HEADER_STATE,
});
