import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface SearchLayerProps {
  open: boolean;
  onClose: () => void;
}
const SearchLayer: React.FC<SearchLayerProps> = props => {
  const navi = useNavigate();
  const [text, setText] = useState<string>("");
  const handleSearch = () => {
    if (text.trim().length > 0) {
      navi(`/list?text=${text.trim()}`);
      props.onClose();
    }
  };
  return (
    <div id="ly_search" className="a-layer-search" style={{ display: props.open ? undefined : "none" }}>
      <button type="button" className="a-layer-search__close" onClick={() => props.onClose()}>
        <span className="blind">레이어 닫기</span>
      </button>
      <input
        type="text"
        className="a-layer-search__input"
        value={text}
        onChange={e => setText(e.target.value)}
        onKeyDown={e => e.key === "Enter" && handleSearch()}
      />
      <button type="button" className="a-layer-search__button" onClick={handleSearch}>
        <span className="blind">검색</span>
      </button>
    </div>
  );
};

export default SearchLayer;
