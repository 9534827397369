import packageJson from "../../../../package.json";
import { Button } from "@mui/material";
import React from "react";
import { getFcmToken, getSessionKey } from "../../../api";
import { RnAPI } from "../../../api/RnAPI";
import useCart from "../../../hooks/useCart";
import { useEnvironment } from "../../../hooks/useEnvironment";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { RNOS, RNVer } from "../../../types/elements";
import { useLocation, useNavigate } from "react-router-dom";

interface NavBarProps {
  onClickMenu: () => void;
  onClickReserve: () => void;
  onClickSearch: () => void;
  onClickCart: () => void;
  onClickCartPC: () => void;
  onClickMy: () => void;
}
const NavBar: React.FC<NavBarProps> = props => {
  const location = useLocation();
  const navi = useNavigate();

  const { count } = useCart();
  const { isLocalAndDev } = useEnvironment();
  const { isMobile } = useWindowSize();

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    navi(-1);
  };
  return (
    <div className="a-header--box">
      {!isMobile && (
        <button type="button" className="btn_menu" onClick={() => props.onClickMenu()}>
          <span className="blind">메뉴</span>
        </button>
      )}
      <h1>
        <a href="/">
          <img className="logo" alt="" src="/static/logo/AndersonC.png" />
        </a>
      </h1>
      {isLocalAndDev && (
        <Button
          onClick={() => {
            alert(`adscApp: ${(window as any).adscApp},
            fcmToken: ${(window as any).fcmToken},
            fcm: ${getFcmToken()},
            skey: ${getSessionKey()},
            os: ${window.localStorage.getItem(RNOS)},
            ver:${window.localStorage.getItem(RNVer)},
            `);
            RnAPI.postMessage({
              type: "log",
              value: JSON.stringify({
                test: "1",
                adscApp: (window as any).adscApp,
                fcmToken: (window as any).fcmToken,
                fcm: getFcmToken(),
                skey: getSessionKey(),
              }),
            });
          }}>
          dev {packageJson.version}
        </Button>
      )}
      <div className="a-header--gnb">
        <a href="#" className="btn_reservation" onClick={() => props.onClickReserve()}>
          쇼룸 예약
        </a>
        <button type="button" className="btn_search" onClick={() => props.onClickSearch()}>
          <span className="blind">검색</span>
        </button>
        {location.pathname !== "/" && (
          <a href="#" className="btn_prev" onClick={handleBack}>
            뒤로 가기
          </a>
        )}
        <button type="button" id="btnMobileCart" className="btn_cart view_m" onClick={() => props.onClickCart()}>
          <span className="blind">장바구니</span>
          {count !== 0 && <span className="cart_count">{count}</span>}
        </button>
        {/* <!-- 장바구니 PC 노출 --> */}
        <a href="#" className="btn_cart view_pc" onClick={() => props.onClickCartPC()}>
          <span className="blind">장바구니</span>
          {count !== 0 && <span className="cart_count">{count}</span>}
        </a>
        {/* <!-- // 장바구니 PC 노출 --> */}
        {!isMobile && (
          <a href="#" className="btn_mypage" onClick={() => props.onClickMy()}>
            <span className="blind">마이페이지</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default NavBar;
