import { gql } from "apollo-boost";
import {
  convertUserAlertInfo,
  convertUserNoti,
  SearchResult,
  SimpleResult,
  User,
  UserAlertInfo,
  UserNoti,
  UserNotiSearchOption,
} from "../types/elements";
import { client } from "./client";

interface QueryIF {
  user?: {
    alertInfos?: UserAlertInfo[];
    userNotis?: SearchResult<UserNoti>;
    unreadUserNotiCount?: number;
  };
}
interface MutationIF {
  user?: {
    putUserProfile?: SimpleResult;
    toggleFavorite?: SimpleResult;
    setUserAlertInfo?: SimpleResult;
    markReadUserNoti?: SimpleResult;
    delUserNoti?: SimpleResult;
    delAllUserNoti?: SimpleResult;
  };
}

export const alertInfos = () =>
  client
    .query<QueryIF>({
      query: gql`
        query GetUserAlertInfos {
          user {
            alertInfos {
              id
              type
              type_id
            }
          }
        }
      `,
    })
    .then(result => {
      if (result.data.user?.alertInfos) return result.data.user.alertInfos.map(r => convertUserAlertInfo(r) ?? {});
      throw new Error("Err");
    });

export const getUserNotis = (sopt: UserNotiSearchOption): Promise<SearchResult<UserNoti>> =>
  client
    .query<QueryIF>({
      query: gql`
        query GetUserNotis($sopt: UserNotiSearchInput) {
          user {
            userNotis(sopt: $sopt) {
              offset
              limit
              total
              list {
                id
                user_id
                created
                readed
                title
                body
                imageUrl
                dataUrl
              }
            }
          }
        }
      `,
      variables: { sopt },
    })
    .then(result => {
      if (result.data.user?.userNotis)
        return {
          ...result.data.user?.userNotis,
          list: result.data.user?.userNotis.list?.map(r => convertUserNoti(r) ?? {}),
        };
      throw new Error("Err");
    });

export const putUserProfile = (user: User) =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation PutUserProfile($user: UserInput) {
          user {
            putUserProfile(user: $user) {
              ok
              count
              errtype
            }
          }
        }
      `,
      variables: { user },
    })
    .then(result => {
      if (result.data?.user?.putUserProfile) return result.data.user.putUserProfile;
      throw new Error("Err");
    });
export const toggleFavorite = (item_id: number, toggle: boolean) =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation ToggleFavorite($item_id: BigInt, $toggle: Boolean) {
          user {
            toggleFavorite(item_id: $item_id, toggle: $toggle) {
              ok
              id
            }
          }
        }
      `,
      variables: { item_id, toggle },
    })
    .then(result => {
      if (result.data?.user?.toggleFavorite) return result.data.user.toggleFavorite;
      throw new Error("Err");
    });

export const setUserAlertInfo = (infos: UserAlertInfo[]) =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation SetUserAlertInfo($infos: [UserAlertInfoInput]) {
          user {
            setUserAlertInfo(infos: $infos) {
              ok
              count
              deleted
            }
          }
        }
      `,
      variables: { infos },
    })
    .then(result => {
      if (result.data?.user?.setUserAlertInfo) return result.data.user.setUserAlertInfo;
      throw new Error("Err");
    });

export const markReadUserNoti = (ids: number[], mark: boolean) =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation MarkReadUserNoti($ids: [BigInt], $mark: Boolean) {
          user {
            markReadUserNoti(ids: $ids, mark: $mark) {
              ok
              count
            }
          }
        }
      `,
      variables: { ids, mark },
    })
    .then(result => {
      if (result.data?.user?.markReadUserNoti) return result.data.user.markReadUserNoti;
      throw new Error("Err");
    });

export const delUserNoti = (ids: number[]) =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation DelUserNoti($ids: [BigInt]) {
          user {
            delUserNoti(ids: $ids) {
              ok
              count
            }
          }
        }
      `,
      variables: { ids },
    })
    .then(result => {
      if (result.data?.user?.delUserNoti) return result.data.user.delUserNoti;
      throw new Error("Err");
    });
export const delAllUserNoti = () =>
  client
    .mutate<MutationIF>({
      mutation: gql`
        mutation DelAllUserNoti {
          user {
            delAllUserNoti {
              ok
              count
            }
          }
        }
      `,
    })
    .then(result => {
      if (result.data?.user?.delAllUserNoti) return result.data.user.delAllUserNoti;
      throw new Error("Err");
    });

export const getUnreadUserNotiCount = () =>
  client
    .query<QueryIF>({
      query: gql`
        query GetUnreadUserNotiCount {
          user {
            getUnreadUserNotiCount
          }
        }
      `,
    })
    .then(result => {
      return result.data.user?.unreadUserNotiCount ?? 0;
    });
