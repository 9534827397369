import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useGlobal } from "../../hooks/useGlobal";
import { getRNOS } from "../../api";
import { useEnvironment } from "../../hooks/useEnvironment";

const IOS_APP_STORE = `https://itunes.apple.com/app/1665376689`;
const AND_APP_STORE = "https://play.google.com/store/apps/details?id=com.andersoncofficial.app";

interface AppSuggestionBottomBannerProps {}
const AppSuggestionBottomBanner: React.FC<AppSuggestionBottomBannerProps> = props => {
  const { isMobile } = useWindowSize();
  const { isLocalAndDev } = useEnvironment();
  const { showAppSuggestionOnMW, setShowAppSuggestionOnMW, setDimmed } = useGlobal();
  const close = () => {
    setShowAppSuggestionOnMW(false);
    setDimmed(false);
  };
  useEffect(() => {
    if (getRNOS()) close();
    else if (isMobile && showAppSuggestionOnMW) {
      setDimmed(true);
    }
  }, [isMobile, showAppSuggestionOnMW]);

  const isIphone =
    navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
    navigator.userAgent.toLowerCase().indexOf("android") === -1;

  const appstorelink = isIphone ? IOS_APP_STORE : AND_APP_STORE;

  const handleClickOpenApp = (e: React.MouseEvent) => {
    e.preventDefault();
    const clickedAt = Date.now();
    const target = isLocalAndDev ? "dev" : "prod";

    // 반응이 없으면 앱스토어로 이동
    setTimeout(() => {
      if (Date.now() - clickedAt < 2000) {
        if (window.confirm("앤더슨씨 앱 최신 버전이 설치되어 있지 않습니다.   \n설치 페이지로 이동하시겠습니까?"))
          window.location.href = appstorelink;
      }
    }, 1000);

    // URI Scheme 으로 앱 호출
    const url = `andersoncofficialapp://${target}` + window.location.pathname;
    setTimeout(() => {
      window.location.href = url;
    }, 0);
  };

  return isMobile ? (
    <>
      {/* <div className="dim"></div> */}
      <div
        id="ly_appdownload"
        className="a-layer-download slideInBottom modal-bottom animated"
        style={{ display: showAppSuggestionOnMW ? "block" : "none" }}>
        <div className="a-layer-download__box">
          <img src="/static/app_logo.png" className="a-layer-download__image" alt="andersonc" />
          <strong className="blind">앱 다운로드 팝업</strong>
          <p className="a-layer-download__title">
            앤더슨씨 앱을 다운받고
            <br />더 편리하게 이용해보세요!
          </p>
          <a href="#" onClick={handleClickOpenApp} className="a-layer-download__link" style={{color: 'white'
        }}>
            앱 이용하기
          </a>
          <button type="button" className="a-layer-download__option" onClick={close}>
            괜찮아요. 모바일웹으로 볼게요.
          </button>
        </div>
        <button type="button" className="a-layer-download__close" onClick={close}>
          닫기
        </button>
      </div>
    </>
  ) : (
    <></>
  );
};

export default AppSuggestionBottomBanner;
