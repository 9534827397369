import { useRecoilValue } from "recoil";
import { WindowResizeState } from "../store/states";

export const useWindowSize = () => {
  const windowResize = useRecoilValue(WindowResizeState);

  return {
    isRNApp: (window as any).isRNApp as boolean,
    isMobile: windowResize.isMobile,
    width: windowResize.width,
    height: windowResize.height,
    initialized: windowResize.initialized,
  };
};
