import React, { useEffect } from "react";
import "./App.scss";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AuthState, setAuth } from "./store/states/AuthState";
import { HeaderState } from "./store/states/HeaderState";

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { gqlapi } from "./graphql/gqlapi";
import { useWindowSize } from "./hooks/useWindowSize";
import { WindowResizeState } from "./store/states";
import useCart from "./hooks/useCart";
import useConstant from "./hooks/useConstant";
import TopPopper from "./components/organisms/TopPopper";
import TopSnack from "./components/organisms/TopSnack";

import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import TitleFix from "./components/pages/layout/TitleFix";
import AppSuggestionBottomBanner from "./components/layout/AppSuggestionBottomBanner";

// static
import MainView from "./components/pages/main/MainView";
import useBanners from "./hooks/useBanners";
import { RnAPI } from "./api/RnAPI";
const AboutView = React.lazy(() => import("./components/pages/AboutView"));
const AgreementView = React.lazy(() => import("./components/pages/AgreementView"));
const PrivacyView = React.lazy(() => import("./components/pages/PrivacyView"));
const NospamView = React.lazy(() => import("./components/pages/NospamView"));
const FnbChungdamView = React.lazy(() => import("./components/pages/fnb/FnbChungdamView"));
const FnbSamsungView = React.lazy(() => import("./components/pages/fnb/FnbSamsungView"));
const FnbDosanView = React.lazy(() => import("./components/pages/fnb/FnbDosanView"));
const FnbHannamView = React.lazy(() => import("./components/pages/fnb/FnbHannamView"));
const FnbApgujungView = React.lazy(() => import("./components/pages/fnb/FnbApgujungView"));

// Main
const JoinView = React.lazy(() => import("./components/pages/JoinView"));
const LoginView = React.lazy(() => import("./components/pages/login/LoginView"));
const FindPwdView = React.lazy(() => import("./components/pages/FindPwdView"));
const ItemInfoView = React.lazy(() => import("./components/pages/iteminfo/ItemInfoView"));
const ItemListView = React.lazy(() => import("./components/pages/itemlist/ItemListView"));
const CartView = React.lazy(() => import("./components/pages/CartView"));
const NewOrderView = React.lazy(() => import("./components/pages/neworder/NewOrderView"));

const ConsignmentView = React.lazy(() => import("./components/pages/sr/ConsignmentView"));
const StylingView = React.lazy(() => import("./components/pages/sr/StylingView"));
const RentalView = React.lazy(() => import("./components/pages/sr/RentalView"));

// etc views
const FavoriteView = React.lazy(() => import("./components/pages/FavoriteView"));
const GuideView = React.lazy(() => import("./components/pages/GuideView"));
const PersonalPayView = React.lazy(() => import("./components/pages/PersonalPayView"));
const LoginViewM = React.lazy(() => import("./components/pages/login/LoginViewM"));
const MyServiceListView = React.lazy(() => import("./components/pages/mypage_old/myservicelist/MyServiceListView"));
const MyServiceDetailView = React.lazy(
  () => import("./components/pages/mypage_old/myservicedetail/MyServiceDetailView")
);
const MyProfileView = React.lazy(() => import("./components/pages/mypage_old/myprofile/MyProfileView"));
const UserAlertInfoSettingView = React.lazy(() => import("./components/pages/alertsetting/UserAlertInfoSettingView"));
const UserNotiView = React.lazy(() => import("./components/pages/usernoti/UserNotiView"));
const ReservationNewView = React.lazy(() => import("./components/pages/reservation/ReservationNewView"));
const ReservationDetailView = React.lazy(() => import("./components/pages/reservation/ReservationDetailView"));
const MyPageView = React.lazy(() => import("./components/pages/mypage/MyPageView"));
const IntroView = React.lazy(() => import("./components/pages/IntroView"));

// Deprecated
const MyView = React.lazy(() => import("./components/pages/mypage_old/MyPageView"));
const OrderListView = React.lazy(() => import("./components/pages/orderlist/OrderListView"));
const MyReservationView = React.lazy(() => import("./components/pages/mypage_old/myreservation/MyReservationView"));

const ScrollToTop: React.FC = props => {
  const location = useLocation();
  useEffect(() => {
    const pathsplit = location.pathname.split("/").filter((o: any) => o);
    if (["detail", "shipping", "qna"].indexOf(pathsplit[pathsplit.length - 1]) === -1) {
      window.document.body.scrollTop = 0;
    }
  }, [location]);
  return <></>;
};

interface AppProps {}

const App: React.FC<AppProps> = props => {
  const { isMobile } = useWindowSize();
  const [authState, setAuthState] = useRecoilState(AuthState);
  const headerState = useRecoilValue(HeaderState);

  const { refresh: refreshCart } = useCart();
  const { load: loadConstant } = useConstant();
  const { showingBanners } = useBanners();

  const load = () => {
    //
    gqlapi.checkMyOrderPaid();
    gqlapi
      .getInitialData()
      .then(user => {
        if (user) {
          setAuth(authState, setAuthState, user);
          // console.log(user);
          refreshCart();
        } else {
          setAuthState({ ...authState, status: "DONE" });
        }
      })
      .catch(err => {});
  };

  useEffect(() => {
    // init mobile device
    RnAPI.postMessage({ type: "imp-uid", value: (window as any)["IMPUID"] });

    // init default data
    loadConstant().catch(err => {
      console.log("error", err);
    });

    // init user info
    load();
  }, []);

  const setWindowResize = useSetRecoilState(WindowResizeState);
  useEffect(() => {
    const handleResize = () => {
      setWindowResize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        isMobile: document.documentElement.clientWidth < 768,
        initialized: true,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Router>
        <Header banners={showingBanners} />
        {headerState.openSearchLayer && <div style={{ height: "56px" }}></div>}
        <div className={"appDiv "} style={{ marginTop: 80 + (showingBanners.length > 0 ? 54 : 0) + "px" }}>
          <ScrollToTop />
          {/* <Header isMobile={isMobile} /> */}

          <div style={{ backgroundColor: "white" }}>
            <div style={{ minHeight: "28rem" }}>
              <React.Suspense>
                <Routes>
                  <Route path="/alertinfo" element={<TitleFix elm={<UserAlertInfoSettingView />} />} />
                  <Route path="/noti" element={<TitleFix elm={<UserNotiView />} />} />

                  <Route path="/reservation" element={<TitleFix elm={<ReservationNewView />} />} />
                  <Route path="/reservation/:reservationId" element={<TitleFix elm={<ReservationDetailView />} />} />
                  {/* // TODO remove it
              <Route path="/reservation" element={<TitleFix elm={<ReservationView />} />} /> */}

                  <Route path="/mypage" element={<TitleFix elm={<MyPageView />} />} />
                  <Route path="/mypage/:subId" element={<TitleFix elm={<MyPageView />} />} />
                  <Route path="/mypage/:subId/:detailId" element={<TitleFix elm={<MyPageView />} />} />
                  <Route path="/mypage/:subId/:detailId/:detailSubId" element={<TitleFix elm={<MyPageView />} />} />
                  {/* // TODO remove it */}
                  <Route path="/my" element={<TitleFix elm={<MyView />} />} />

                  <Route path="/intro" element={<TitleFix elm={<IntroView />} />} />
                  <Route path="/intro/:subId" element={<TitleFix elm={<IntroView />} />} />

                  <Route path="/about" element={<TitleFix elm={<AboutView />} />} />
                  <Route path="/list" element={<TitleFix elm={<ItemListView />} t="온라인샵 - 앤더슨씨" />} />
                  <Route path="/item/:itemId" element={<ItemInfoView />} />
                  <Route path="/item/:itemId/:tab" element={<TitleFix elm={<ItemInfoView />} />} />
                  <Route path="/cart" element={<TitleFix elm={<CartView />} />} />
                  <Route path="/newOrder" element={<TitleFix elm={<NewOrderView />} />} />
                  <Route path="/order" element={<TitleFix elm={<OrderListView />} />} />

                  <Route path="/join" element={<TitleFix elm={<JoinView />} />} />
                  <Route path="/login" element={<TitleFix elm={isMobile ? <LoginViewM /> : <LoginView />} />} />

                  <Route path="/myservices" element={<TitleFix elm={<MyServiceListView />} />} />
                  <Route path="/myconsignments" element={<TitleFix elm={<MyServiceListView type="Consignment" />} />} />
                  <Route path="/myrentals" element={<TitleFix elm={<MyServiceListView type="Rental" />} />} />
                  <Route path="/mystylings" element={<TitleFix elm={<MyServiceListView type="Styling" />} />} />
                  <Route path="/myservices/:id" element={<TitleFix elm={<MyServiceDetailView />} />} />
                  <Route path="/myreservation" element={<TitleFix elm={<MyReservationView />} />} />
                  <Route path="/findpassword" element={<TitleFix elm={<FindPwdView />} />} />
                  <Route path="/favorite" element={<TitleFix elm={<FavoriteView />} />} />
                  <Route path="/profile" element={<TitleFix elm={<MyProfileView />} />} />
                  <Route path="/guide" element={<TitleFix elm={<GuideView />} />} />
                  <Route path="/agreement" element={<TitleFix elm={<AgreementView />} />} />
                  <Route path="/privacy" element={<TitleFix elm={<PrivacyView />} />} />
                  <Route path="/nospam" element={<TitleFix elm={<NospamView />} />} />
                  <Route path="/personal" element={<TitleFix elm={<PersonalPayView />} />} />

                  {/* 서비스 신청 */}
                  <Route path="/consignment/*" element={<TitleFix elm={<ConsignmentView />} />} />
                  <Route path="/styling/*" element={<TitleFix elm={<StylingView />} />} />
                  <Route path="/rental/*" element={<TitleFix elm={<RentalView />} />} />

                  {/* FnB  */}
                  <Route path="/fnb_chungdam/*" element={<TitleFix elm={<FnbChungdamView />} />} />
                  <Route path="/fnb_and_haven/*" element={<TitleFix elm={<FnbSamsungView />} />} />
                  <Route path="/fnb_dosan/*" element={<TitleFix elm={<FnbDosanView />} />} />
                  <Route path="/fnb_hannam/*" element={<TitleFix elm={<FnbHannamView />} />} />
                  <Route path="/fnb_apgujung/*" element={<TitleFix elm={<FnbApgujungView />} />} />

                  {/* <Route path="/" element={isMobile ? <MainViewM /> : <MainView />} /> */}
                  <Route path="/" element={<TitleFix elm={<MainView />} />} />
                </Routes>
              </React.Suspense>
            </div>
            <Footer />
          </div>
          <TopSnack isMobile={isMobile} />
          <TopPopper />
        </div>
      </Router>
      {isMobile && <AppSuggestionBottomBanner />}
    </>
  );
};

export default App;
