import { atom, selector } from "recoil";
import { Cart } from "../../types/elements";
import { Ls } from "../../api";

interface NewOrderStateIF {
  orderId?: number;
  m_id: string;
  useremail: string;
  userphone: string;
  username: string;

  addr_zipcode: string;
  addr_addr1: string;
  addr_addr2: string;
  addr_phone: string;
  addr_username: string;

  deliver_msg: string;
  carts: Cart[];
  price: number;
  itemPrice: number;
  vatPrice: number;
  orderDone: boolean;
  payType: "direct" | "credit";

  // etc info
  directName: string;
  cashReceipt: "yes" | "no";
  cashReceiptTarget: "personal" | "company";
  cashReceiptPhoneNumber: string;
  cashReceiptCompanyNumber: string;
  cashReceiptCompanyBusinessName: string;
  cashReceiptCompanyOwnerName: string;
  cashReceiptCompanyEmail: string;
  checkedAgreement: boolean;
  is_paying: boolean;
  is_ready: boolean;
}
export const INIT_NEW_ORDER_STATE: NewOrderStateIF = {
  orderId: undefined,
  m_id: "",
  userphone: "",
  username: "",
  useremail: "",

  addr_zipcode: "",
  addr_addr1: "",
  addr_addr2: "",
  addr_phone: "",
  addr_username: "",

  deliver_msg: "",
  carts: [],

  price: 0,
  itemPrice: 0,
  vatPrice: 0,
  orderDone: false,
  payType: "credit",

  directName: "",
  cashReceipt: "yes",
  cashReceiptTarget: "personal",
  cashReceiptPhoneNumber: "",
  cashReceiptCompanyNumber: "",
  cashReceiptCompanyBusinessName: "",
  cashReceiptCompanyOwnerName: "",
  cashReceiptCompanyEmail: "",
  checkedAgreement: false,
  is_paying: false,
  is_ready: false,
};

const loadedState = Ls.getItem("NEWORDERSTATE");
export const NewOrderState = atom<NewOrderStateIF>({
  key: "OrderState",
  default: loadedState
    ? {
        payType: "credit",
        cashReceipt: "yes",
        directName: "",
        cashReceiptTarget: "personal",
        cashReceiptCompanyNumber: "",
        ...loadedState,
        carts: loadedState.carts ? loadedState.carts : [],
        orderDone: false,
        is_paying: false,
      }
    : INIT_NEW_ORDER_STATE,
});

export const SyncNewOrderStateSelector = selector<NewOrderStateIF>({
  key: "SyncNewOrderStateSelector",
  get: ({ get }) => {
    return get(NewOrderState);
  },
  set: ({ set, get }, _newOrderState) => {
    const newOrderState: NewOrderStateIF = _newOrderState as NewOrderStateIF;
    const nos = get(NewOrderState);
    const merged = { ...nos, ...newOrderState };

    merged.carts = merged.carts?.map(o => {
      const ret = { ...o };
      return ret;
    });

    if (merged.orderDone === false) {
      if (merged.carts.length > 0) {
        merged.itemPrice = merged.carts
          .map(o => (o.item?.price && o.count ? o.item?.price * o.count : 0))
          .reduce((p, n) => (p ? p : 0) + (n ? n : 0), 0);
        merged.vatPrice = merged.carts
          .map(o => (o.item?.price && o.item.vatAdded !== true && o.count ? o.item.price * o.count * 0.1 : 0))
          .reduce((p, n) => (p ? p : 0) + (n ? n : 0), 0);
        merged.price = merged.itemPrice + merged.vatPrice;
      } else {
        merged.itemPrice = 0;
        merged.vatPrice = 0;
        merged.price = 0;
      }
    }

    if (
      merged.carts.length > 0 &&
      merged.useremail &&
      merged.username &&
      merged.useremail &&
      merged.addr_zipcode &&
      merged.addr_addr1 &&
      merged.addr_addr2 &&
      merged.addr_phone &&
      merged.addr_username &&
      (merged.payType === "credit" || (merged.payType === "direct" && merged.directName))
    ) {
      merged.is_ready = true;
    } else {
      merged.is_ready = false;
    }
    if (
      merged.payType === "direct" &&
      merged.cashReceipt === "yes" &&
      merged.cashReceiptTarget === "personal" &&
      !merged.cashReceiptPhoneNumber
    )
      merged.is_ready = false;

    if (
      merged.payType === "direct" &&
      merged.cashReceiptTarget === "company" &&
      (!merged.cashReceiptCompanyNumber ||
        !merged.cashReceiptCompanyBusinessName ||
        !merged.cashReceiptCompanyOwnerName ||
        !merged.cashReceiptCompanyEmail)
    )
      merged.is_ready = false;

    Ls.setItem("NEWORDERSTATE", merged);
    set(NewOrderState, merged);
  },
});
