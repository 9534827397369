import { atom } from "recoil";
import { COUNT_OF_LIST, Item, ItemSearchOption } from "../../types/elements";
import { UIRequestStatus } from "../types";

interface ItemListStateIF {
  list: Item[];
  limit: number;
  total: number;
  offset: number;
  loading: UIRequestStatus;
  sopt: ItemSearchOption;
  scrollTop: number;
}

const INIT_ITEMLIST_STATE: ItemListStateIF = {
  list: [],
  limit: COUNT_OF_LIST,
  total: 0,
  offset: 0,
  loading: "INITIALIZED",
  sopt: { limit: COUNT_OF_LIST, offset: 0, isSoldoutLast: false, visibles: ["OPEN"], ordering: "recently" },
  scrollTop: 0,
};

export const ItemListState = atom<ItemListStateIF>({
  key: "ItemListState",
  default: INIT_ITEMLIST_STATE,
});
