import { useRecoilState } from "recoil";
import { AuthState, ConstantState } from "../store/states";
import { Ls } from "../api";

const useBanners = () => {
  const [constantState, setConstantState] = useRecoilState(ConstantState);
  const [authState, setAuthState] = useRecoilState(AuthState);

  // Banner data
  const showingBanners = constantState.banners
    .filter(o => o.id && constantState.closed_banner_ids.indexOf(o.id) === -1)
    .filter(o => (o.onlyShowNonLogin ? o.show && authState.status === "DONE" && !authState.data.id : o.show));

  const closeBanner = (banner_id: number) => {
    const closed_banner_ids: number[] = Ls.getItems("CLOSED_BANNER_IDS") ?? [];
    const updated_closed_banner_ids = Array.from(new Set([...closed_banner_ids, banner_id]));
    Ls.setItems("CLOSED_BANNER_IDS", updated_closed_banner_ids);
    setConstantState(cs => ({ ...cs, closed_banner_ids: updated_closed_banner_ids }));
  };

  return { banners: constantState.banners, showingBanners, closeBanner };
};

export default useBanners;
