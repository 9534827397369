import React from 'react';
import { Button } from '@mui/material';

export interface ButtonBoxProps {
  border?: string;
  boxStyle?:
    | 'white'
    | 'black'
    | 'gray'
    | 'gray808080'
    | 'yellow'
    | 'white-border-black';
  className?: string;
  style?: React.CSSProperties;
  label?: string;
  onClick?: (e: any) => any;
  helperText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
}

const ButtonBox: React.FC<ButtonBoxProps> = (props) => {
  return (
    <Button
      className={
        'buttonBox ' +
        props.boxStyle +
        (props.className ? ' ' + props.className : '')
      }
      color={'inherit'}
      onClick={props.onClick}
      style={{
        ...props.style,
        borderWidth:
          props.boxStyle &&
          [
            'gray',
            'yellow',
            'gray808080',
            'black',
            'white',
            'white-border-black',
          ].includes(props.boxStyle)
            ? props.border
              ? props.border
              : '2px'
            : undefined,
      }}
      disabled={props.disabled === true ? true : false}
      disableRipple
      fullWidth={props.fullWidth}
    >
      {props.label}
      {props.children}
    </Button>
  );
};

export default ButtonBox;
