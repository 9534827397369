import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useConstant from "../../../hooks/useConstant";
import { useRecoilValue } from "recoil";
import { ConstantState, WindowResizeState } from "../../../store/states";
import { CustomImage } from "../../../types/elements";
import PcMainSlide from "./slider/PcMainSlide";
import { useWindowSize } from "../../../hooks/useWindowSize";
import MainNewItemList from "./slider/MainNewItemList";
import { Ls } from "../../../api";

const STATIC_CUSTOM_SLIDE_1 = "STATIC_CUSTOM_SLIDE_1";
const STATIC_CUSTOM_SLIDE_3 = "STATIC_CUSTOM_SLIDE_3";

const STATIC_IMAGE_MAIN = "STATIC_IMAGE_MAIN";

interface MainViewProps {}

const MainView: React.FC<MainViewProps> = props => {
  const navi = useNavigate();
  const windowResizeState = useRecoilValue(WindowResizeState);
  const constantState = useRecoilValue(ConstantState);
  const { isMobile } = useWindowSize();

  const { getConstant, getCustomImages } = useConstant();
  const [customImages1, setCustomImages1] = useState<CustomImage[]>(Ls.getItems(STATIC_CUSTOM_SLIDE_1));
  const [defaultImage1, setDefaultImage1] = useState<string>("");
  const [customImages3, setCustomImages3] = useState<CustomImage[]>(Ls.getItems(STATIC_CUSTOM_SLIDE_3));

  useEffect(() => {
    if (isMobile) {
      const scs1 = getCustomImages(STATIC_CUSTOM_SLIDE_1);
      setCustomImages1(scs1);
      Ls.setItems(STATIC_CUSTOM_SLIDE_1, scs1);
    } else {
      const scs3 = getCustomImages(STATIC_CUSTOM_SLIDE_3);
      setCustomImages3(scs3);
      Ls.setItems(STATIC_CUSTOM_SLIDE_3, scs3);
    }

    setDefaultImage1((getConstant(STATIC_IMAGE_MAIN) ?? "/topimg.png") as string);
  }, [constantState.constants, isMobile]);

  const handleClickImage = (url?: string) => {
    console.log("handleClickImage", url);
    if (url) {
      if (url.indexOf("http") === 0) {
        window.open(url);
      } else {
        navi(url);
      }
    }
  };

  const go = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    navi(path);
  };

  return (
    <>
      <div id="wrap">
        <main className="main">
          <PcMainSlide
            width={windowResizeState.width}
            customImages={isMobile ? customImages1 : customImages3}
            defaultImageUrl={defaultImage1}
            onClickImage={handleClickImage}
          />

          <div className="main__inner">
            <a href="#" className="a_btn" onClick={go("/reservation")}>
              쇼룸 예약하기
            </a>
            <ul className="main__list">
              <li>
                <div className="main__list--box">
                  <strong className="main__list--title">디자인가구</strong>
                  <p className="main__list--text">오리지날 빈티지, 리프로덕션 중고, 리프로덕션 새상품</p>
                  <img src="/static/furimg1.png" alt="" className="main__list--img" />
                  <div className="main__list--button">
                    <a href="#" className="btn_go" onClick={go("/list")}>
                      쇼핑하기
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div className="main__list--box">
                  <strong className="main__list--title">공간스타일링</strong>
                  <p className="main__list--text">예산에 맞는 가구 컨설팅</p>
                  <img src="/static/furimg2.png" alt="" className="main__list--img" />
                  <div className="main__list--button">
                    <a href="#" className="btn_show" onClick={go("/styling/portfolio")}>
                      포트폴리오
                    </a>
                    <a href="#" className="btn_go" onClick={go("/styling/info")}>
                      신청하기
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div className="main__list--box">
                  <strong className="main__list--title">매입/위탁</strong>
                  <p className="main__list--text">디자인 가구 매입, 위탁</p>
                  <img src="/static/furimg3.png" alt="" className="main__list--img" />
                  <div className="main__list--button">
                    <a href="#" className="btn_go" onClick={go("/consignment")}>
                      신청하기
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div className="main__list--box">
                  <strong className="main__list--title">렌탈 서비스</strong>
                  <p className="main__list--text">디자인 가구 대여</p>
                  <img src="/static/furimg4.png" alt="" className="main__list--img" />
                  <div className="main__list--button">
                    <a href="#" className="btn_show" onClick={go("/rental/portfolio")}>
                      포트폴리오
                    </a>
                    <a href="#" className="btn_go" onClick={go("/rental/info")}>
                      신청하기
                    </a>
                  </div>
                </div>
              </li>
            </ul>

            <strong className="main__title">NEW</strong>
            <MainNewItemList />

            <div className="main__vintage">
              <img src="/static/funimg6.jpg" alt="" className="main__vintage--img" />
              <a href="#" onClick={go("/list")}>
                빈티지 컬렉션 쇼핑하기
              </a>
            </div>
            {/* <HCardPromition /> */}
          </div>
        </main>
      </div>
    </>
  );
};

export default MainView;
